const ErrorView = () => {
  return (
    <div>
      <h4
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        존재하지 않는 페이지입니다. <br /> <br />
        주소 또는 파라미터를 다시 확인해주세요.
      </h4>
    </div>
  );
};

export default ErrorView;
