import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { NotoSans16 } from '../../../styles/fontStyls';
import {
  ChatBubblesObject,
  ClickDataFuction,
  ClientNameProps,
  StyleThemeProps,
} from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { setModalData } from '../../../modules/modal';

interface BotProps {
  index: number;
  content: ChatBubblesObject;
  clickData: ClickDataFuction;
}

const ChattingBubbles = ({ index, content, clickData }: BotProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const dispatch = useDispatch();
  const imageModalOpen = () => {
    dispatch(setModalData({ state: 'open', url: content.image }));
  };

  return (
    <div>
      <Response key={index}>
        <TextWrap>
          <div>
            <ResText
              clientname={clientName}
              state={content.bubbleState}
              action={content.action ? false : true}
            >
              {content.image !== '' ? (
                <div className="imageWrap">
                  <img
                    src={content.image}
                    alt="chattingbubble"
                    onClick={imageModalOpen}
                  ></img>
                </div>
              ) : (
                ''
              )}
              {content.message === ''
                ? ''
                : content.message.split('\n').map((ele, index) => {
                    const phoneNumber = ele.match(/\d{2,3}-\d{3,4}-\d{4}/);
                    const emailAddress: any = ele.match(
                      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                    );
                    let url: any = ele.match(/(https?:\/\/[^ ]*)/);
                    if (!url) url = ele.match(/(http?:\/\/[^ ]*)/);
                    if (phoneNumber) {
                      const messageArr = ele.split(phoneNumber[0]);
                      return (
                        <ResTextP
                          key={index}
                          clientname={clientName}
                          state={ele === ''}
                        >
                          {messageArr[0]}
                          <button
                            onClick={() => {
                              document.location.href = `tel:${phoneNumber}`;
                            }}
                          >
                            {phoneNumber}
                          </button>
                          {messageArr[1]}
                        </ResTextP>
                      );
                    }
                    if (emailAddress) {
                      const emailTag = `<a href=mailto:${emailAddress} target="_blank">${emailAddress}</a>`;

                      ele = ele.replace(emailAddress, emailTag);
                      return (
                        <>
                          <ResTextP
                            key={index}
                            clientname={clientName}
                            state={ele === ''}
                          >
                            <ResTextA
                              dangerouslySetInnerHTML={{ __html: ele }}
                            ></ResTextA>
                          </ResTextP>
                        </>
                      );
                    }
                    if (url) {
                      const urlTag = `<a href=${url[0]} target="_blank">${url[0]}</a>`;
                      ele = ele.replace(url[0], urlTag);

                      return (
                        <>
                          <ResTextP
                            key={index}
                            clientname={clientName}
                            state={ele === ''}
                          >
                            <ResTextA
                              dangerouslySetInnerHTML={{ __html: ele }}
                            ></ResTextA>
                          </ResTextP>
                        </>
                      );
                    }

                    return (
                      <ResTextP
                        key={index}
                        clientname={clientName}
                        state={ele === ''}
                      >
                        {ele} {phoneNumber}
                      </ResTextP>
                    );
                  })}
              {content.action
                ? content.action.map(
                    (item: { name: string; value: string }, index) => (
                      <Action
                        key={index}
                        clientname={clientName}
                        onClick={() => {
                          window.open(item.value);
                        }}
                      >
                        {item.name}
                      </Action>
                    )
                  )
                : ''}
              {/* {<button onClick={()=>{document.location.href='tel:010-1234-5678'}}></button>} */}
            </ResText>
          </div>
        </TextWrap>
      </Response>
      {content.buttons !== null ? (
        <EmotionWrap>
          <RelationButtonWrap clientname={clientName} state={false}>
            <Button
              onClick={() => {
                const positiveText = content.buttons?.positive
                  ? content.buttons?.positive
                  : '네';
                clickData('User', 'text', positiveText, null);
              }}
            >
              <p>
                {content.buttons.positive === ''
                  ? '네'
                  : content.buttons.positive}
              </p>
            </Button>
          </RelationButtonWrap>
          <RelationButtonWrap clientname={clientName} state={false}>
            <Button
              onClick={() => {
                const negativeText = content.buttons?.negative
                  ? content.buttons?.negative
                  : '아니오';
                clickData('User', 'text', negativeText, null);
              }}
            >
              <p>
                {content.buttons.negative === ''
                  ? '아니오'
                  : content.buttons.negative}
              </p>
            </Button>
          </RelationButtonWrap>
        </EmotionWrap>
      ) : (
        ''
      )}
    </div>
  );
};

export default ChattingBubbles;
interface ResTextProps {
  state: boolean;
  clientname: ClientNameProps;
  action: boolean;
}

type RelationButtonProps = {
  state: boolean;
  clientname: ClientNameProps;
};
const Response = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  margin-right: 18px;
  width: calc(80% - 18px);
`;

const TextWrap = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
const ResText = styled.div<ResTextProps>`
  width: ${({ action }) => (action ? 'auto' : '100%')};
  padding: 10px 16px;
  margin-top: 4px;
  display: inline-flex;
  flex-direction: column;
  background: ${({ theme, clientname }) =>
    theme[clientname].chattingBubbles.resText.background};
  border: ${({ theme, clientname }) =>
    theme[clientname].chattingBubbles.resText.border};
  box-sizing: border-box;
  border-radius: ${(props) => {
      if (props.state) {
        return '0px';
      } else {
        return '22px';
      }
    }}
    22px 22px 22px;
  .imageWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: fit-content;
      max-width: 100%;
      object-fit: cover;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }
`;
interface ResTextPProps {
  state: boolean;
  clientname: string;
}
const ResTextP = styled.div<ResTextPProps>`
  ${NotoSans16}
  /* identical to box height, or 26px */
  height: ${({ state }) => (state ? '25px' : '')};
  letter-spacing: -0.025em;
  color: ${({ theme, clientname }) =>
    theme[clientname].chattingBubbles.resText.color};
  margin: 0;
  button {
    border: 0px;
    color: blue;
    background-color: inherit;
  }
`;

const ResTextA = styled.div`
  ${NotoSans16}
  letter-spacing: -0.025em;
  margin: 0;
`;

const EmotionWrap = styled.div`
  margin-left: 18px;
  margin-right: 18px;
  display: flex;
  align-items: flex-start;
`;
const Action = styled.button<StyleThemeProps>`
  ${NotoSans16}
  font-weight: bold;
  min-height: 44px;
  background: ${({ theme, clientname }) =>
    theme[clientname].chattingBubbles.action.background};
  border: ${({ theme, clientname }) =>
    theme[clientname].chattingBubbles.action.border};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 36px;
  margin: 3px 0 3px 0;
  text-align: center;
  color: ${({ theme, clientname }) =>
    theme[clientname].chattingBubbles.action.color};
  &:hover {
    background: ${({ theme, clientname }) =>
      theme[clientname].chattingBubbles.action.hoverColor};
  }
  &:nth-child(1) {
    margin: 5px 0 3px 0;
  }
`;
const RelationButtonWrap = styled.div<RelationButtonProps>`
  button {
    height: 43px;
    background: ${({ theme, clientname, state }) =>
      state
        ? theme[clientname].chattingBubbles.relationButton.background.on
        : theme[clientname].chattingBubbles.relationButton.background.off};
    border: ${({ theme, clientname }) =>
      theme[clientname].chattingBubbles.relationButton.border};
    box-sizing: border-box;
    /* Carousel_1 */
    box-shadow: 4px 6px 14px rgba(57, 57, 57, 0.08);
    border-radius: 16px;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 6px;
    margin-top: 14px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 10px 16px;
    &:hover {
      background: ${({ theme, clientname }) =>
        theme[clientname].chattingBubbles.relationButton.hover.background};
      p {
        color: ${({ theme, clientname }) =>
          theme[clientname].chattingBubbles.relationButton.hover.color};
      }
    }
    &:active {
      background: ${({ theme, clientname }) =>
        theme[clientname].chattingBubbles.relationButton.active.background};
    }
    p {
      ${NotoSans16}
      font-weight: 700;
      /* identical to box height, or 150% */
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme, clientname, state }) =>
        state
          ? theme[clientname].chattingBubbles.relationButton.p.color.on
          : theme[clientname].chattingBubbles.relationButton.p.color.off};
      padding: 0px;
      margin: 0px;
    }
  }
`;
