import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import closeButton from '../../assets/modal/close.svg';
import { RootState } from '../../modules';
import { setModalData } from '../../modules/modal';

const Modal = () => {
  const { modalData } = useSelector((state: RootState) => ({
    modalData: state.modal,
  }));

  const dispatch = useDispatch();
  const imageModalClose = () =>
    dispatch(setModalData({ state: 'close', url: 'none' }));

  return (
    <>
      {modalData.state === 'open' && (
        <ModalBackGroundWrap>
          <ModalWrap>
            <CloseButtonWrap>
              <img
                src={closeButton}
                alt="closeButton"
                onClick={imageModalClose}
              ></img>
            </CloseButtonWrap>
            <img src={modalData.url} alt="chattingbubble"></img>
          </ModalWrap>
        </ModalBackGroundWrap>
      )}
    </>
  );
};

export default Modal;

const ModalWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 337px;
  max-height: 700px;
  background: #fff;
  z-index: 10;
  border: 1 px solid #ccc;

  img {
    width: 100%;
  }
`;

const CloseButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  img {
    width: 25px;
    height: 25px;
  }
`;
const ModalBackGroundWrap = styled.div`
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
`;
