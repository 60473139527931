import jardin from './custom/jardin';
import mdtpcb from './custom/mdtpcb';
import gjto from './custom/gjto';
import nextstory from './custom/nextstory';
import xcrew from './custom/xcrew';
import xcrewinfo from './custom/xcrew-info';
import kfo from './custom/kfo';
import kfoedu from './custom/kfo';
import kfowork from './custom/kfo-work';
import sunil2021_1 from './custom/sunil2021-1';
import sunil2021_2 from './custom/sunil2021-2';
import smartig from './custom/smartig';
import sample1 from './custom/sample1';
import sample2 from './custom/sample2';
import case1 from './custom/case1';
import dept_bc from './custom/dept-bc';
import pspfnd from './custom/pspfnd';
import focu from './custom/focu';
import debc from './custom/debc';
import investseoul from './custom/investseoul';
import kbstar from './custom/kbstar';
import seocho from './custom/seocho';
import visitjeju from './custom/visitjeju';
import jejucom from './custom/jejucom';
import sscb from './custom/sscb';

const defaults = {
  title: 'assets/custom/jardin/title.png',
  chattingBubbles: {
    resText: {
      background: '#F5F5F5',
      border: ' 1.5px solid rgba(196, 196, 196, 0.3)',
      color: '#111111',
    },
    action: {
      background: '#FFFFFF',
      border: '1.5px solid #111111',
      hoverColor: '#F5F5F5',
      color: '#111111',
    },
    relationButton: {
      background: {
        on: '#111111',
        off: '#FFFFFF',
      },
      border: '1.7px solid #111111',
      hover: {
        background: '#f5f5f5',
        color: '#111111',
      },
      active: {
        background: '#111111',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#111111',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#111111',
      color: '#FFFFFF',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1px solid #111111',
        off: '1px solid #F3F3F3',
      },
    },
    title: {
      color: '#111111',
    },
    swiper: {
      color: '#111111',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#111111',
      color: '#FFFFFF',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #111111',
        off: '1.5px solid #EAEAEA',
      },
      hover: '1.5px solid #111111',
    },
    text: {
      background: '#FFFFFF',
      color: '#111111',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#FFFFFF',
      border: '1px solid #EDEDED',
    },
    button: {
      background: {
        on: '#111111',
        off: '#FAFAFA',
      },
      border: {
        on: '#111111',
        off: '#EDEDED',
      },
      font: {
        on: '#FFFFFF',
        off: '#111111',
      },
    },
  },
  relation: {
    button: {
      background: {
        on: '#111111',
        off: '#FFFFFF',
      },
      border: '1.5px solid #111111',
      hover: {
        background: '#f5f5f5',
      },
      active: {
        background: '#111111',
      },
      color: {
        on: '#FFFFFF',
        off: '#111111',
      },
    },
    listbutton: {
      background: '#FFFFFF',
      border: '1.5px solid #111111',
      hoverBackground: '#F5F5F5',
    },
    listboxtext: {
      color: '#111111',
    },
    listele: {
      backgroundColor: {
        odd: '#E4E4E4',
        even: '#FFFFFF',
      },
      color: '#111111',
      hoverBackground: '#C4C4C4',
    },
  },
  bookmark: {
    wrap: {
      background: 'white',
    },
    button: {
      background: 'white',
    },
  },
  header: {
    wrap: {
      background: 'white',
    },
    company: {
      background: 'white',
    },
    title: {
      color: '#111111',
    },
  },
  input: {
    wrap: {
      background: 'white',
    },
    postWrap: {
      border: {
        on: '1px solid #111111',
        off: '1px solid #E7E7E7',
      },
    },
    sendbutton: {
      background: 'black',
    },
  },
  visualpage: {
    header: {
      background: '#111111',
      color: '#FFFFFF',
    },
  },
  intent: {
    color: '#111111',
  },
};
const theme = {
  jardin,
  mdtpcb,
  gjto,
  nextstory,
  xcrew,
  xcrewinfo,
  kfo,
  kfoedu,
  kfowork,
  'sunil2021-1': sunil2021_1,
  'sunil2021-2': sunil2021_2,
  smartig,
  sample1,
  sample2,
  case1,
  'dept-bc': dept_bc,
  pspfnd,
  focu,
  debc,
  investseoul,
  kbstar,
  seocho,
  visitjeju,
  jejucom,
  sscb,
  defaults,
};
export default theme;
