import { AnyAction } from "redux";

const ADDVISUALPAGE = 'visualpage/Add'
const OPENVISUALPAGE = 'visualpage/Open';
const CLOSEVISUALPAGE = 'visualpage/CLOSE';

const addVisualPage = (array:VisualPageState)=>({
  type:ADDVISUALPAGE,
  array
})

const openVisualPage = ()=>({
  type:OPENVISUALPAGE
})

const closeVisualPage = ()=>({
  type:CLOSEVISUALPAGE
})

const initialState:VisualPageState= {
  source:[],
  state:false
}

const visualPage = (
  state:VisualPageState = initialState,
  action:AnyAction
)=>{
  switch(action.type){
    case ADDVISUALPAGE:
      return {
        source:action.array ,
        state:false
      }
    case OPENVISUALPAGE:
      return {
        ...state,
        state:true
      }
    case CLOSEVISUALPAGE:
      return {
        ...state,
        state:false
      }
    default:
      return state
  }
}

type VisualPageState = {
  source:any;
  state:boolean;
};
export {
  addVisualPage,
  openVisualPage,
  closeVisualPage
}

export type{
  VisualPageState
}

export default visualPage;