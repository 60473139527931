const kfo = {
  title: 'assets/custom/kfo/title.svg',
  wrap: {
    background: '#ffffff',
  },
  chattingBubbles: {
    resText: {
      background: '#F5F5F5',
      border: ' 1px solid rgba(196, 196, 196, 0.3)',
      color: '#111111',
    },
    action: {
      background: '#ffffff',
      border: '1.5px solid #C32A24',
      hoverColor: '#FEF8F6',
      color: '#C32A24',
    },
    relationButton: {
      background: {
        on: '#D01700',
        off: '#ff361d',
      },
      border: '1.5px solid #d01700',
      hover: {
        background: '#D01700',
        color: '#fffff',
      },
      active: {
        background: '#D01700',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#ffffff',
        },
      },
    },
  },
  user: {
    reqText: {
      background: 'linear-gradient(90deg, #C32A24 15.18%, #DD4F20 100%)',
      color: '#ffffff',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1px solid #ff361d',
        off: '1px solid rgba(64, 64, 64, 0.3)',
      },
      background: '#393939',
    },
    image: {
      background: '#ffffff',
    },
    swiper: {
      color: 'rgb(195, 42, 36)',
    },
    title: {
      color: '#C32A24',
      background: '#ffffff',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: 'linear-gradient(90deg, #C32A24 15.18%, #DD4F20 100%)',
      color: '#ffffff',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #C32A24',
        off: '1px solid #EAEAEA',
      },
      hover: '1.5px solid #111111',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      background: '#ffffff',
      color: '#C32A24',
      height: '24px',
    },
    imgEle: {
      height: '72px',
      background: '#ffffff',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#ffffff',
      border: '1px solid #e5e5e5',
    },
    button: {
      background: {
        on: '#C32A24',
        off: '#FEF8F6',
      },
      border: {
        on: '#C32A24',
        off: '#F6ECE9',
      },
      font: {
        on: '#FFFFFF',
        off: '#C32A24',
      },
    },
    content: {
      color: '#383838',
    },
    image: {
      background: '#ffffff',
    },
  },
  relation: {
    button: {
      background: {
        on: '#C32A24',
        off: '#ffffff',
      },
      border: '1.5px solid #C32A24',
      hover: {
        background: '#FEF8F6',
        color: '#C32A24',
      },
      active: {
        background: '#C32A24',
        color: '#ffffff',
      },
      color: {
        on: '#ffffff',
        off: '#C32A24',
      },
    },
    listbutton: {
      background: '#ffffff',
      border: '1.5px solid #C32A24',
      hoverBackground: '#FEF8F6',
    },
    listboxtext: {
      color: '#C32A24',
      fillColor: '#C32A24',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#ffffff',
        even: '#FEF8F6',
      },
      color: '#111111',
      hoverBackground: '#FEF8F6',
    },
    listmenu: {
      background: '#ffffff',
      border: '1.5px solid #C32A24',
    },
  },
  bookmark: {
    wrap: {
      background: '#ffffff',
    },
    button: {
      background: '#ffffff',
    },
    relation: {
      color: '#3F2B2B',
    },
  },
  header: {
    wrap: {
      background: 'linear-gradient(90deg, #C32A24 15.18%, #DD4F20 100%)',
    },
    company: {
      background: 'rgba(0,0,0,0)',
    },
    title: {
      color: 'white',
    },
  },
  chatting: {
    wrap: {
      background: '#ffffff',
    },
  },
  client: {
    title: {
      color: '#111111',
    },
  },
  input: {
    inputWrap: {
      background: '#ffffff',
    },
    wrap: {
      background: '#ffffff',
    },
    postWrap: {
      border: {
        on: '1px solid #C32A24',
        off: '1px solid rgba(63, 43, 43, 0.1)',
      },
      background: {
        on: '#ffffff',
        off: '#FEF8F6',
      },
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#FEF8F6',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17,17,17,0.2)',
    },
    sendbutton: {
      background: '#C32A24',
    },
  },
  visualpage: {
    header: {
      background: 'linear-gradient(90deg, #C32A24 15.18%, #DD4F20 100%)',
      color: '#FFFFFF',
    },
    wrap: {
      background: '#ffffff',
    },
    footer: {
      color: '#C4C4C4',
    },
  },
  intent: {
    color: '#C32A24',
  },
};
export default kfo;
