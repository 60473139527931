const visitjeju = {
  title: 'assets/custom/visitjeju/title.png',
  chattingBubbles: {
    resText: {
      background:
        'linear-gradient(0deg, rgba(21, 42, 140, 0.06), rgba(21, 42, 140, 0.06)), #FFFFFF',
      color: '#111111',
    },
    action: {
      background: '#FFFFFF',
      border: '1.5px solid #FBA138',
      hoverColor:
        'linear-gradient(0deg, rgba(21, 42, 140, 0.06), rgba(21, 42, 140, 0.06)), #F5F5F5',
      color: '#FBA138',
    },
    relationButton: {
      background: {
        on: '#FBA138',
        off: '#FFFFFF',
      },
      border: '1.5px solid #FBA138',
      hover: {
        background: '#F5F5F5',
        color: '#FBA138',
      },
      active: {
        background: '#FBA138',
      },
      p: {
        color: {
          on: 'white',
          off: '#FBA138',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#FBA138',
      color: '#FFFFFF',
    },
    reqTime: {
      color: '#FBA138',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1.5px solid #FBA138',
        off: '1px solid #F3F3F3',
      },
      background: {
        on: '#FAFAFA',
        off: '#ffffff',
      },
    },
    title: {
      color: '#111111',
    },
    swiper: {
      color: '#2763BA',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: 'linear-gradient(90deg, #FFB649 0%, #FF994F 100%)',
      color: '#FFFFFF',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #FBA138',
        off: '1px solid #EAEAEA',
      },
      hover: '1.5px solid #FBA138',
    },
    text: {
      background: '#FFFFFF',
      color: '#111111',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#FFFFFF',
      border: '1px solid #EDEDED',
    },
    button: {
      background: {
        on: 'linear-gradient(90deg, #FFB649 0%, #FF994F 100%)',
        off: '#FAFAFA',
      },
      border: {
        on: '#FBA138',
        off: '#EDEDED',
      },
      font: {
        on: '#FFFFFF',
        off: '#111111',
      },
    },
  },
  relation: {
    button: {
      background: {
        on: '#FBA138',
        off: '#FFFFFF',
      },
      border: '1.5px solid #FBA138',
      hover: {
        background: '#F5F5F5',
      },
      active: {
        background: '#FBA138',
      },
      color: {
        on: '#FFFFFF',
        off: '#FBA138',
      },
    },
    listbutton: {
      background: '#FFFFFF',
      border: '1.5px solid #FBA138',
      hoverBackground: '#F5F5F5',
    },
    listboxtext: {
      color: '#111111',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#F4F4F4',
        even: '#FFFFFF',
      },
      color: '#000000',
      hoverBackground: '#C4C4C4',
    },
  },
  bookmark: {
    wrap: {
      background: 'white',
    },
    button: {
      background: 'white',
    },
    relation: {
      opacity: 0.6,
    },
  },
  header: {
    wrap: {
      background: 'linear-gradient(90deg, #FFB649 0%, #FF994F 100%)',
      boxShadow: 'none',
    },
    company: {
      background: 'linear-gradient(90deg, #FFB649 0%, #FF994F 100%)',
      borderRadidus: '50%',
    },
    title: {
      color: '#FFFFFF',
    },
    img: {
      maxHeight: '29px',
    },
  },
  input: {
    wrap: {
      background: 'white',
    },
    postWrap: {
      border: {
        on: '1px solid #FBA138',
        off: '1px solid #E7E7E7',
      },
      background: {
        on: '#FFFFFF',
        off: '#F7F7F7',
      },
    },
    sendbutton: {
      background: '#FBA138',
    },
    inputWrap: {
      background: '#ffffff',
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#F2F5F8',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17,17,17,0.2)',
    },
  },
  visualpage: {
    header: {
      background: '#FBA138',
      color: '#FFFFFF',
      stroke: '#FFFFFF',
    },
  },
  intent: {
    color: '#FBA138',
  },
};
export default visitjeju;
