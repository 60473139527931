import { AnyAction } from "redux";

const ADDONECARD = 'onecard/AddOnecard';

const addOnecard = (list:OnecardList)=>({
  type:ADDONECARD,
  list
})

const initialState:OnecardState = [];

const onecard = (
  state:OnecardState = initialState,
  action:AnyAction
)=>{
  let newState = state.slice();
  switch(action.type){
    case ADDONECARD:
      newState.push(action.list);
      return newState;
    default:
      return state;
  }
}

type OnecardObject={
  buttonName:string;
  id:string;
  shared:{
    content:string;
    image:string;
    "image-src":string;
  }
}
type OnecardList = OnecardObject[];
type OnecardState = OnecardList[];

export default onecard;
export{
  addOnecard
}
export type{
  OnecardObject,
  OnecardList,
  OnecardState
}