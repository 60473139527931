import { AnyAction } from "redux";

const ADDCHECKERBOARD = 'checkerBoard/AddCheckerBoard';

const addCheckerBoard = (list:CheckerBoardList)=>({
  type:ADDCHECKERBOARD,
  list
})

const initialState:CheckerBoardState = [];

const checkerBoard =(
  state:CheckerBoardState = initialState,
  action:AnyAction
)=>{
  let newState = state.slice()
  switch(action.type){
    case ADDCHECKERBOARD:
      newState.push(action.list)
      return newState
    default:
      return state
  }
}

type CheckerBoardObject = {
	buttonName:string;
	id:string;
	image:string;
  type:string;
};

type CheckerBoardList = CheckerBoardObject[];
type CheckerBoardState = CheckerBoardList[];

export default checkerBoard;
export{
  addCheckerBoard
}
export type{
  CheckerBoardObject,
  CheckerBoardList,
  CheckerBoardState
}