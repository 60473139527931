import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ChattingBubbles from './ChatBubbles/ChattingBubbles';
import User from './ChatBubbles/User';
import Client from './Client';
import Loading from './Loading';
import Carousel from './ResponseType/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import Relation from './ResponseType/Relation';
import CheckerBoard from './ResponseType/CheckerBoard';
import OneCard from './ResponseType/OneCard';
import MBVisualPage from './VisualPage';
import { ClientNameProps } from '../../types';
import {
  addRendingData,
  ChattingObject,
  ChattingType,
} from '../../modules/chatting';
import { buttonOff, switchState } from '../../modules/bookMark';
import { getTime, responseParsing } from '../../api/functions';
import { addUserBubbles } from '../../modules/userChatting';
import { postMessage2 } from '../../api/axiosFunction';
import Greeting from './Greeting';
import { answerGenerator } from '../../modules/thunks';

const Chatting = () => {
  // redux state
  const chatState = useSelector((state: RootState) => ({
    chatbubbles: state.chatbubbles,
    carousel: state.carousel,
    checkerBoard: state.checkerBoard,
    userChatting: state.userChatting,
    relation: state.relation,
    onecard: state.onecard,
    chatting: state.chatting,
    defaults: state.defaults,
  }));
  const { bookmarkState } = useSelector((state: RootState) => ({
    bookmarkState: state.bookmark.bookmarkState,
  }));
  const { visualPageState } = useSelector((state: RootState) => ({
    visualPageState: state.display,
  }));
  const { buttonClickState } = useSelector((state: RootState) => ({
    buttonClickState: state.bookmark.buttonState,
  }));
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );

  // redux dispatch
  const dispatch = useDispatch();
  const onUserData = (message: string, list: ChattingType) => {
    dispatch(addUserBubbles(message));
    dispatch(addRendingData(list));
  };
  const onSwitchBookMark = () => {
    dispatch(switchState());
  };

  const onBookMarkOff = () => {
    if (buttonClickState) {
      dispatch(buttonOff());
    }
  };
  const clickData = async (
    type: string,
    responseType: string,
    content: string,
    id: string | null
  ) => {
    if (content[0] === '<') {
      const newContent = content.split('>')[1].split('<')[0];
      onUserData(newContent, [
        { type: 'User', id: chatState.userChatting.length, time: getTime() },
      ]);
      const {
        data: { response },
      } = await postMessage2(newContent, id, responseType);
      const responseData = responseParsing(response);
      responseData && dispatch(answerGenerator(responseData));
    } else {
      onUserData(content, [
        { type: 'User', id: chatState.userChatting.length, time: getTime() },
      ]);
      const {
        data: { response },
      } = await postMessage2(content, id, responseType);
      const responseData = responseParsing(response);
      responseData && dispatch(answerGenerator(responseData));
    }
    onSwitchBookMark();
  };

  const wrapRef = useRef<HTMLDivElement | null>(null);

  const controlCase = (ele: ChattingObject, index: number) => {
    switch (ele.type) {
      case 'Client':
        return <Client key={index} index={index} time={ele.time} />;
      case 'ChatBubbles':
        return (
          <ChattingBubbles
            key={index}
            index={index}
            content={chatState.chatbubbles[ele.id]}
            clickData={clickData}
          />
        );
      case 'User':
        return <User key={index} index={index} id={ele.id} time={ele.time} />;
      case 'Loading':
        return <Loading key={index} index={index} />;
      case 'Carousel':
        return (
          <Carousel
            key={index}
            index={index}
            list={chatState.carousel[ele.id]}
            clickData={clickData}
          />
        );
      case 'Relation':
        return (
          <Relation
            key={index}
            index={index}
            relation={chatState.relation[ele.id]}
            listBox={chatState.defaults.listBox}
            clickData={clickData}
          />
        );
      case 'CheckerBoard':
        return (
          <CheckerBoard
            key={index}
            index={index}
            checkerBoard={chatState.checkerBoard[ele.id]}
            clickData={clickData}
          />
        );
      case 'OneCard':
        return (
          <OneCard
            key={index}
            index={index}
            oneCard={chatState.onecard[ele.id]}
            clickData={clickData}
          />
        );
      case 'AutoIntents':
        return (
          <ChattingBubbles
            key={index}
            index={index}
            content={chatState.chatbubbles[ele.id]}
            clickData={clickData}
          />
        );
      case 'Greeting':
        return <Greeting key={index} />;
    }
  };

  const chattingRendering = () => {
    const chattings = chatState.chatting.map((contentArray) => {
      const rendering = contentArray.map((ele, idx) => {
        return controlCase(ele, idx);
      });
      return [...rendering];
    });
    return chattings;
  };

  const scrollDown = () => {
    wrapRef.current?.scrollIntoView({ behavior: 'smooth' });
    wrapRef.current?.scroll(0, wrapRef.current?.scrollHeight);
  };

  useEffect(() => {
    const wrap = wrapRef.current;

    if (!wrap) return;

    const scrollTop: number = wrap?.scrollTop;
    const viewHeight: number = wrap?.getBoundingClientRect().height;
    const height: number = wrap?.scrollHeight;
    const heightFromBottom: number = height - (viewHeight + scrollTop);

    buttonClickState
      ? heightFromBottom < 51 && setTimeout(() => scrollDown(), 300)
      : heightFromBottom < 51 &&
        bookmarkState &&
        setTimeout(() => scrollDown(), 300);
  }, [bookmarkState, buttonClickState]);

  useEffect(() => {
    scrollDown();

    setTimeout(() => {
      scrollDown();
    }, 400);
  }, [chatState.chatting]);

  return (
    <>
      <Wrap
        ref={wrapRef}
        clientname={clientName}
        desktop={visualPageState['state'] === 'Desktop'}
        bookMarkState={bookmarkState}
        buttonState={buttonClickState}
        onClick={onBookMarkOff}
      >
        {chattingRendering()}
      </Wrap>
      <MBVisualPage />
    </>
  );
};

export default Chatting;

interface WrapProps {
  bookMarkState: boolean;
  buttonState: boolean;
  desktop: boolean;
  clientname: string;
}

const Wrap = styled.div<WrapProps>`
  width: 100%;
  /* height: 100%; */
  height: ${(props) =>
    props.bookMarkState
      ? props.buttonState
        ? 'calc(100% - 82px - 58px - 140px)'
        : 'calc(100% - 82px - 58px - 49px)'
      : 'calc(100% - 82px - 58px)'};
  transform: translateY(58px);
  border-top: ${({ theme, clientname }) =>
    theme[clientname].chatting?.wrap?.borderTop};
  /* transition: ${(props) =>
    props.buttonState ? 'all 0.3s linear' : 'none'}; */
  transition: all 0.3s linear;
  padding-bottom: 10px;
  background-color: ${({ theme, clientname }) =>
    theme[clientname].chatting?.wrap.background || 'white'};
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

//response Wrap
