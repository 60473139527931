const ADDBUBBLES = 'chatbubbles/AddChatBubbles';

const addChatBubbles = (obj: ChatBubblesObject) => ({
  type: ADDBUBBLES,
  obj,
});

const initialState: ChatBubblesState = [];

const chatbubbles = (
  state: ChatBubblesState = initialState,
  action: Actions
) => {
  let newState = state.slice();
  switch (action.type) {
    case ADDBUBBLES:
      newState.push(action.obj);
      return newState;
    default:
      return state;
  }
};

//chatBubble
interface actionArrayEle {
  name: string;
  value: string;
  type: string;
}

type ChatBubblesObject = {
  image: string;
  message: string;
  bubbleState: boolean;
  buttons: {
    negative: string;
    positive: string;
  } | null;
  action: actionArrayEle[] | null;
};

type ChatBubblesState = ChatBubblesObject[];

type Actions = ReturnType<typeof addChatBubbles>;

export { addChatBubbles };

export type { ChatBubblesObject, ChatBubblesState, Actions };
export default chatbubbles;
