import styled from "styled-components";
import indicator from '../../assets/indicator.gif';

interface LoadingProps {
  index:number;
}

const Loading =({index}:LoadingProps)=>{
  return(
    <Wrap key={index}>
      <img src={indicator} alt='loading' />
    </Wrap>
  )
};

export default Loading;

const Wrap = styled.div`
  margin-top:4px;
  margin-left: 18px;
  img{
    object-fit: contain;
    width: 40px;
    height: 22px;
  }
`