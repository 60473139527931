const focu = {
  title: 'assets/custom/focu/title.png',
  chattingBubbles: {
    resText: {
      background: '#F2F5F8',
      border: '1px solid #E7E7E7',
      color: '#111111',
    },
    action: {
      background: '#ffffff',
      border: '1.5px solid #2763BA',
      hoverColor: '#F1F7FF',
      color: '#2763BA',
    },
    relationButton: {
      background: {
        on: '#2763BA',
        off: '#FFFFFF',
      },
      border: '1.5px solid #2763BA',
      hover: {
        background: '#F1F7FF',
        color: '#2763BA',
      },
      active: {
        background: '#2763BA',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#2763BA',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#007BFF',
      color: '#FFFFFF',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1.5px solid #2763BA',
        off: '1px solid #F3F3F3',
      },
    },
    title: {
      color: '#2763BA',
    },
    swiper: {
      color: '#2763BA',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#2763BA',
      color: '#FFFFFF',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #2763BA',
        off: '1px solid #EAEAEA',
      },
      hover: '1.5px solid #2763BA',
    },
    text: {
      background: '#FFFFFF',
      color: '#2763BA',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#FFFFFF',
      border: '1px solid #EDEDED',
    },
    button: {
      background: {
        on: '#2763BA',
        off: '#F2F5F8',
      },
      border: {
        on: '#2763BA',
        off: '#EDEDED',
      },
      font: {
        on: '#FFFFFF',
        off: '#2763BA',
      },
    },
  },
  relation: {
    button: {
      background: {
        on: '#2763BA',
        off: '#FFFFFF',
      },
      border: '1.5px solid #2763BA',
      hover: {
        background: '#F1F7FF',
      },
      active: {
        background: '#2763BA',
      },
      color: {
        on: '#FFFFFF',
        off: '#2763BA',
      },
    },
    listbutton: {
      background: '#FFFFFF',
      border: '1.5px solid #2763BA',
      hoverBackground: '#F1F7FF',
    },
    listboxtext: {
      color: '#2763BA',
    },
    listele: {
      backgroundColor: {
        odd: '#E4E4E4',
        even: '#FFFFFF',
      },
      color: '#000000',
      hoverBackground: '#C4C4C4',
    },
  },
  bookmark: {
    wrap: {
      background: 'white',
    },
    button: {
      background: 'white',
    },
  },
  header: {
    wrap: {
      background: '#004098',
    },
    company: {
      background: '#004098',
    },
    title: {
      color: '#ffffff',
    },
  },
  input: {
    wrap: {
      background: 'white',
    },
    postWrap: {
      border: {
        on: '1px solid #004098',
        off: '1px solid #E7E7E7',
      },
      background: {
        on: '#ffffff',
        off: '#F2F5F8',
      },
    },
    sendbutton: {
      background: '#004098',
    },
    inputWrap: {
      background: '#ffffff',
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#F2F5F8',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17,17,17,0.2)',
    },
  },
  visualpage: {
    wrap: {
      width: 'calc(100%)',
    },
    contentsWrap: {
      width: 'calc(100% - 18px)',
    },
    header: {
      background: '#004098',
      color: '#FFFFFF',
    },
  },
  intent: {
    color: '#004098',
  },
};
export default focu;
