import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '.';
import { getTime } from '../api/functions';
import { ResponseDataArrProps } from '../types';
import { addCarousel } from './carousel';
import { addChatBubbles } from './chatBubbles';
import { addLoaing, ChatType, finishLoading, switchData } from './chatting';
import { addCheckerBoard } from './checkerBoard';
import { addOnecard } from './oneCard';
import { addRelation } from './relation';
import { addTemporary } from './temporary';
import { addVisualPage, openVisualPage } from './visualPage';
interface ChattingArrTypes {
  type: ChatType;
  id: number;
  time: string;
}

export const answerGenerator =
  (
    responseArr: ResponseDataArrProps[]
  ): ThunkAction<void, RootState, null, Action<any>> =>
  (dispatch, getState) => {
    // eslint-disable-next-line array-callback-return
    const chattingArr: ChattingArrTypes[] = responseArr.map((ele, index) => {
      switch (ele.type) {
        case 'ChatBubbles':
          dispatch(
            addChatBubbles({
              image: ele.data.image ? ele.data.image : '',
              message: ele.data.message,
              bubbleState: index === 0 ? true : false,
              buttons: null,
              action: ele.data.actions.length !== 0 ? ele.data.actions : null,
            })
          );
          return {
            type: 'ChatBubbles',
            id: getState().chatbubbles.length - 1,
            time: getTime(),
          };
        case 'Relation':
          dispatch(addRelation(ele.data));
          return {
            type: 'Relation',
            id: getState().relation.length - 1,
            time: getTime(),
          };
        case 'VisualPage':
          dispatch(addVisualPage(ele.data));
          return {
            type: 'VisualPage',
            id: 0,
            time: getTime(),
          };
        case 'CheckerBoard':
          dispatch(addCheckerBoard(ele.data));
          return {
            type: 'CheckerBoard',
            id: getState().checkerBoard.length - 1,
            time: getTime(),
          };
        case 'Carousel':
          dispatch(addCarousel(ele.data));
          return {
            type: 'Carousel',
            id: getState().carousel.length - 1,
            time: getTime(),
          };
        case 'OneCard':
          dispatch(addOnecard(ele.data));
          return {
            type: 'OneCard',
            id: getState().onecard.length - 1,
            time: getTime(),
          };
        case 'AutoIntents':
          dispatch(
            addChatBubbles({
              image: ele.data.image ? ele.data.image : '',
              message: ele.data.message,
              bubbleState: true,
              buttons: {
                negative: ele.data.negativeButton,
                positive: ele.data.positiveButton,
              },
              action: null,
            })
          );
          return {
            type: 'AutoIntents',
            id: getState().chatbubbles.length - 1,
            time: getTime(),
          };
        default:
          return {
            type: 'null',
            id: 0,
            time: '',
          };
      }
    });
    let timeArr: Array<number> = [];
    chattingArr.forEach((ele, index) => {
      if (index === 0) {
        return timeArr.push(1000);
      } else if (ele?.type === 'AutoIntents') {
        // 2.5
        return timeArr.push(timeArr[index - 1] + 3500);
      } else {
        return timeArr.push(timeArr[index - 1] + 1000);
      }
    });

    dispatch(addLoaing(getTime()));
    // eslint-disable-next-line array-callback-return
    chattingArr.some((ele, index) => {
      if (index === 0 && chattingArr.length !== 1) {
        setTimeout(() => {
          dispatch(switchData(ele, getState().chatting.length - 1));
        }, timeArr[index]);
      } else if (index === 0 && chattingArr.length === 1) {
        setTimeout(() => {
          dispatch(finishLoading(ele, getState().chatting.length - 1));
        }, timeArr[index]);
      } else if (ele?.type === 'VisualPage') {
        setTimeout(() => {
          dispatch(openVisualPage());
        }, timeArr[index]);
        setTimeout(() => {
          dispatch(
            finishLoading(
              {
                type: 'null',
                id: 0,
                time: '',
              },
              getState().chatting.length - 1
            )
          );
        }, timeArr[index] + 1500);
        return true;
      } else if (ele?.type === 'AutoIntents') {
        setTimeout(() => {
          dispatch(
            switchData(
              { type: 'Client', id: 0, time: getTime() },
              getState().chatting.length - 1
            )
          );
        }, timeArr[index - 1] + 500);
        setTimeout(() => {
          dispatch(finishLoading(ele, getState().chatting.length - 1));
        }, timeArr[index]);
      } else if (index === chattingArr.length - 1) {
        setTimeout(() => {
          dispatch(finishLoading(ele, getState().chatting.length - 1));
        }, timeArr[index]);
      } else {
        setTimeout(() => {
          dispatch(switchData(ele, getState().chatting.length - 1));
        }, timeArr[index]);
      }
    });

    const visualPageIndex = chattingArr.findIndex(
      (ele) => ele.type === 'VisualPage'
    );
    if (visualPageIndex > -1) {
      dispatch(addTemporary(chattingArr.slice(visualPageIndex + 1)));
    }
  };
