import { lazy, Suspense, useState } from 'react';
import ErrorComponent from './error/errorview';
import GlobalStyle from './globalStyle';

const VisualPage = ({ params }: VisualPageProps) => {
  const [error, setError]: any = useState(null);

  if (!params?.clientname || !params?.pagename) {
    return <div></div>;
  }

  const Component = lazy(() =>
    import(`./${params?.clientname}/${params?.pagename}`).catch((err: any) => {
      setError(true);
    })
  );

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <Suspense fallback={<div></div>}>
      <GlobalStyle />
      <Component />
    </Suspense>
  );
};

interface VisualPageProps {
  params: {
    clientname: string;
    pagename: string;
  } | null;
}

export default VisualPage;
