const jardin = {
  title: 'assets/custom/jardin/title.png',
  chattingBubbles: {
    resText: {
      background: '#EAEBEE',
      border: '1.5px solid #EAEBEE',
      color: '#111111',
    },
    action: {
      background: '#FFFFFF',
      border: '1.5px solid #FF6A28',
      hoverColor: '#FFE1D4',
      color: '#FF6A28',
    },
    relationButton: {
      background: {
        on: '#FF6A28',
        off: '#FFFFFF',
      },
      border: '1.7px solid #FF6A28',
      hover: {
        background: '#FFE1D4',
        color: '#FF6A28',
      },
      active: {
        background: '#FF6A28',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#FF6A28',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#FF6A28',
      color: '#FFFFFF',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '0.5px solid #FF6A28',
        off: '0.5px solid #7F7F80',
      },
    },
    title: {
      color: '#FF6A28',
    },
    swiper: {
      color: 'rgb(255, 106, 40)',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#FF6A28',
      color: '#FFFFFF',
    },
  },
  checkerboard: {
    ele: {
      border: {
        on: '1px solid #FF6A28',
        off: '1px solid #FF6A28',
      },
      hover: '1.5px solid #FF6A28',
    },
    text: {
      background: '#FFFFFF',
      color: '#FF6A28',
    },
    img: {
      background: '#FFFFFF',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '72px',
    },
  },
  onecard: {
    wrap: {
      background: '#FFFFFF',
      border: '1px solid #EDEDED',
    },
    button: {
      background: {
        on: '#FF6A28',
        off: '#FAFAFA',
      },
      border: {
        on: '#FF6A28',
        off: '#FAFAFA',
      },
      font: {
        on: '#FFFFFF',
        off: '#FF6A28',
      },
    },
  },
  relation: {
    button: {
      background: {
        on: '#FF6A28',
        off: '#FFFFFF',
      },
      border: '1.7px solid #FF6A28',
      hover: {
        background: '#FFE1D4',
      },
      active: {
        background: '#FF6A28',
      },
      color: {
        on: '#FFFFFF',
        off: '#FF6A28',
      },
    },
    listbutton: {
      background: '#ffffff',
      border: '1.5px solid #FF6A28',
      hoverBackground: '#FFE1D4',
    },
    listboxtext: {
      color: '#FF6A28',
    },
    listele: {
      backgroundColor: {
        odd: '#FFFFFF',
        even: '#FFFFFF',
      },
      color: '#111111',
      hoverBackground: '#FFE1D4',
    },
  },
  bookmark: {
    wrap: {
      background: '#D4D4D4',
    },
    button: {
      background: '#D4D4D4',
    },
  },
  header: {
    wrap: {
      background: '#FF6A28',
    },
    company: {
      background: '#FF6A28',
    },
    title: {
      color: '#FFFFFF',
    },
  },
  input: {
    wrap: {
      background: '#D4D4D4',
    },
    postWrap: {
      border: {
        on: '1px solid #FF6A28',
        off: '1px solid #DADADA',
      },
    },
    sendbutton: {
      background: '#FF6A28',
    },
  },
  visualpage: {
    header: {
      background: '#FF6A28',
      color: '#FFFFFF',
    },
  },
  intent: {
    color: '#FF6A28',
  },
};
export default jardin;
