import styled from 'styled-components';
import { ReactComponent as Next } from '../../../assets/RelationList/Next.svg';
import { useState } from 'react';
import {
  ClickDataFuction,
  ClientNameProps,
  OnecardList,
  StyleThemeProps,
} from '../../../types';
import { PoppinsAndNotoSans14 } from '../../../styles/fontStyls';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';

interface OneCardProps {
  index: number;
  oneCard: OnecardList;
  clickData: ClickDataFuction;
}

interface RelationButtonProps {
  buttonName: string;
  id: string;
  clickData: ClickDataFuction;
}

const RelationButton = ({ buttonName, id, clickData }: RelationButtonProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const [state, setState] = useState(false);
  return (
    <RelationButtonWrap
      clientname={clientName}
      state={state}
      onClick={() => {
        clickData('OneCard', 'button', buttonName, id);
        setState(true);
      }}
    >
      <p>{buttonName}</p>
      <NextButton state={state} clientname={clientName} />
    </RelationButtonWrap>
  );
};

const OneCard = ({ index, oneCard, clickData }: OneCardProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  return (
    <OneCardWrap clientname={clientName}>
      <OnecardImage src={oneCard[0].shared.image} clientname={clientName} />
      <Content
        dangerouslySetInnerHTML={{ __html: oneCard[0].shared.content }}
        clientname={clientName}
      />
      {oneCard.map((ele, idx) => {
        return (
          <RelationButton
            key={idx}
            buttonName={ele.buttonName}
            id={ele.id}
            clickData={clickData}
          />
        );
      })}
    </OneCardWrap>
  );
};

export default OneCard;

type RelationButtonWrapProps = {
  state: boolean;
  clientname: string;
};

type NextButtonProps = {
  state: boolean;
  clientname: string;
};

const OneCardWrap = styled.div<StyleThemeProps>`
  width: 290px;
  background: ${({ theme, clientname }) =>
    theme[clientname].onecard.wrap.background};
  border: ${({ theme, clientname }) => theme[clientname].onecard.wrap.border};
  box-sizing: border-box;
  /* Carousel_1 */

  box-shadow: 4px 6px 14px rgba(57, 57, 57, 0.08);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  margin-top: 14px;
  overflow: hidden;
`;
const OnecardImage = styled.img<StyleThemeProps>`
  width: 100%;
  height: 169px;
  border-radius: 24px 24px 0px 0px;
  object-fit: ${({ theme, clientname }) =>
    theme[clientname].onecard?.image?.objectFit || 'cover'};
  background: ${({ theme, clientname }) =>
    theme[clientname].onecard?.image?.background};
`;
const Content = styled.div<StyleThemeProps>`
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  padding: 20px;
  p {
    margin: 0px;
    color: ${({ theme, clientname }) =>
      theme[clientname].onecard?.content?.color};
    ${PoppinsAndNotoSans14}
  }
`;

const RelationButtonWrap = styled.button<RelationButtonWrapProps>`
  width: 100%;
  min-height: 44px;
  padding: 12px 14px 12px 20px;
  background: ${({ state, theme, clientname }) =>
    state
      ? theme[clientname].onecard.button.background.on
      : theme[clientname].onecard.button.background.off};
  border: ${({ state, theme, clientname }) =>
    state
      ? theme[clientname].onecard.button.border.on
      : theme[clientname].onecard.button.border.off};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    margin: 0px;
    padding: 0px;
    text-align: left;
    max-width: 230px;
    color: ${({ state, theme, clientname }) =>
      state
        ? theme[clientname].onecard.button.font.on
        : theme[clientname].onecard.button.font.off};
  }
`;

const NextButton = styled(Next)<NextButtonProps>`
  path {
    stroke: ${({ state, theme, clientname }) =>
      state
        ? theme[clientname].onecard.button.font.on
        : theme[clientname].onecard.button.font.off};
  }
`;
