import styled from 'styled-components';
import { ReactComponent as Dropdown } from '../../../assets/RelationList/Dropdown.svg';
import { Menu, MenuItem, Button, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import {
  ClickDataFuction,
  ClientNameProps,
  RelationList,
  RelationObject,
  StyleThemeProps,
} from '../../../types';
import { NotoSans16 } from '../../../styles/fontStyls';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';

interface RelationProps {
  index: number;
  relation: RelationList;
  listBox: string;
  clickData: ClickDataFuction;
}

interface RelationEleProps {
  ele: RelationObject;
  idx: number;
  clickData: ClickDataFuction;
}

const RelationButtonEle = ({ ele, idx, clickData }: RelationEleProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const [btnState, setState] = useState(false);
  return (
    <RelationButtonWrap key={idx} clientname={clientName} btnstate={btnState}>
      <Button
        onClick={() => {
          clickData('Relation', 'button', ele.name, ele.id);
          setState(true);
        }}
      >
        <p>{ele.name}</p>
      </Button>
    </RelationButtonWrap>
  );
};

const Relation = ({ index, relation, listBox, clickData }: RelationProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const RelationButtons = () => {
    return relation.map((ele, idx) => {
      return (
        <RelationButtonEle
          key={idx}
          ele={ele}
          idx={idx}
          clickData={clickData}
        />
      );
    });
  };
  const RelationLists = () => {
    return (
      <RelationListsWrap clientname={clientName}>
        <Button onClick={handleClick}>
          <ListBoxText clientname={clientName}>{listBox}</ListBoxText>
          <DropdownButton clientname={clientName} />
        </Button>
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          clientname={clientName}
          onClose={() => {
            handleClose();
          }}
        >
          {relation.map((ele, idx) => {
            return (
              <SelectListEle
                key={idx}
                clientname={clientName}
                backgroundstate={idx}
                onClick={() => {
                  handleClose();
                  clickData('Relation', 'button', ele.name, ele.id);
                }}
              >
                <Tooltip title={ele.value} placement="right">
                  <div>{ele.name}</div>
                </Tooltip>
              </SelectListEle>
            );
          })}
        </StyledMenu>
      </RelationListsWrap>
    );
  };

  return (
    <RelationWrap>
      {relation?.length >= 8 ? RelationLists() : RelationButtons()}
    </RelationWrap>
  );
};

export default Relation;

type RelationButtonProps = {
  btnstate: boolean;
  clientname: string;
};
type SelectListEleProps = {
  backgroundstate: number;
  clientname: string;
};

const RelationWrap = styled.div`
  width: calc(100% - 36px);
  margin-right: 18px;
  margin-left: 18px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const RelationButtonWrap = styled.div<RelationButtonProps>`
  button {
    background: ${({ btnstate, theme, clientname }) =>
      btnstate
        ? theme[clientname].relation.button.background.on
        : theme[clientname].relation.button.background.off};
    border: ${({ theme, clientname }) =>
      theme[clientname].relation.button.border};
    box-sizing: border-box;
    /* Carousel_1 */
    box-shadow: 4px 6px 14px rgba(57, 57, 57, 0.08);
    border-radius: 16px;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 6px;
    margin-top: 14px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 10px 16px;
    &:hover {
      background: ${({ theme, clientname }) =>
        theme[clientname].relation.button.hover.background};
    }
    &:hover p {
      color: ${({ theme, clientname }) =>
        theme[clientname].relation.button?.hover?.color};
    }
    &:active {
      background: ${({ theme, clientname }) =>
        theme[clientname].relation.button.active.background};
      color: ${({ theme, clientname }) =>
        theme[clientname].relation.button?.active?.color};
    }
    p {
      ${NotoSans16}
      font-weight: 700;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: ${({ theme, clientname }) =>
        theme[clientname].relation.button?.textAlign || 'center'};
      text-transform: uppercase;
      color: ${({ btnstate, theme, clientname }) =>
        btnstate
          ? theme[clientname].relation.button.color.on
          : theme[clientname].relation.button.color.off};
      padding: 0px;
      margin: 0px;
    }
  }
`;

const RelationListsWrap = styled.div<StyleThemeProps>`
  min-width: 270px;
  max-width: 300px;
  width: 100%;
  margin-top: 10px;
  button {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme, clientname }) =>
      theme[clientname].relation.listbutton.background};
    border: ${({ theme, clientname }) =>
      theme[clientname].relation.listbutton.border};
    box-sizing: border-box;
    /* Carousel_1 */
    box-shadow: 4px 6px 14px rgba(57, 57, 57, 0.08);
    border-radius: 24px;
    &:hover {
      background-color: ${({ theme, clientname }) =>
        theme[clientname].relation.listbutton.hoverBackground};
    }
  }
`;
const DropdownButton = styled(({ ...props }) => (
  <Dropdown {...props} />
))<StyleThemeProps>`
  path {
    fill: ${({ theme, clientname }) => {
      const fillColor = theme[clientname].relation.listboxtext?.fillColor;
      return fillColor
        ? fillColor
        : theme[clientname].relation.listboxtext.color;
    }};
  }
`;

const ListBoxText = styled.p<StyleThemeProps>`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: ${({ theme, clientname }) =>
    theme[clientname].relation.listboxtext?.fontWeight || 'bold'};
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme, clientname }) =>
    theme[clientname].relation.listboxtext.color};
  margin: 0px;
  margin-left: 10px;
`;

const SelectListEle = styled(({ ...props }) => (
  <MenuItem {...props} />
))<SelectListEleProps>`
  width: 276px;
  min-height: 44px;
  transition: all 0.3s linear;
  background-color: ${({ backgroundstate, theme, clientname }) =>
    backgroundstate % 2 !== 0
      ? theme[clientname].relation.listele.backgroundColor.odd
      : theme[clientname].relation.listele.backgroundColor.even};
  padding: 5px 12px;
  border: 0px;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  word-break: break-all;
  white-space: break-spaces;
  color: ${({ theme, clientname }) => theme[clientname].relation.listele.color};
  &:hover {
    background-color: ${({ theme, clientname }) =>
      theme[clientname].relation.listele.hoverBackground};
  }
`;
const StyledMenu = styled(({ className, ...props }) => (
  <Menu {...props} classes={{ paper: className }} />
))`
  background: ${({ theme, clientname }) =>
    theme[clientname].relation?.listmenu?.background || '#ffffff'};
  border: ${({ theme, clientname }) =>
    theme[clientname].relation?.listmenu?.border || '1px solid #e9eaef'};
  box-sizing: border-box;
  /* Carousel_1 */
  box-shadow: 4px 6px 14px rgba(57, 57, 57, 0.08);
  border-radius: 24px;
  width: 300px;
  padding: 0px 12px;
`;
