const MODALDATA = 'modal/ModalState';

export const setModalData = ({ state, url }: ModalType) => ({
  type: MODALDATA,
  state,
  url,
});

interface ModalType {
  state: string;
  url: string;
}

const initailState: ModalType = {
  state: 'close',
  url: 'none',
};

const modal = (state: ModalType = initailState, action: Actions) => {
  switch (action.type) {
    case MODALDATA:
      return { ...state, state: action.state, url: action.url };

    default:
      return state;
  }
};

type ModalStateActions = ReturnType<typeof setModalData>;

export type { ModalType };
export type Actions = ModalStateActions;

export default modal;
