import { AnyAction } from 'redux';
import { ClientNameProps } from '../types';

const ADDINTENT = 'default/AddIntent';
const ADDCLIENTINFO = 'default/AddClientInfo';
const ADDCLIENTNAME = 'default/AddClientName';

const addIntent = (intentData: IntentType) => ({
  type: ADDINTENT,
  intentData,
});

const addClientInfo = (
  name: string,
  image: string,
  listBox: string,
  placeholder: string
) => ({
  type: ADDCLIENTINFO,
  name,
  image,
  listBox,
  placeholder,
});

const addClientName = (name: string) => ({
  type: ADDCLIENTNAME,
  name,
});

const initialState: DefaultsState = {
  name: '',
  image: '',
  listBox: '',
  placeholder: '',
  intent: [],
  clientName: 'defaults',
};

const defaults = (state: DefaultsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case ADDINTENT:
      return {
        ...state,
        intent: action.intentData,
      };
    case ADDCLIENTINFO:
      return {
        ...state,
        name: action.name,
        image: action.image,
        listBox: action.listBox,
        placeholder: action.placeholder,
      };
    case ADDCLIENTNAME:
      return {
        ...state,
        clientName: action.name,
      };
    default:
      return state;
  }
};

type IntentObject = {
  count: number;
  id: string;
  name: string;
  is_activate: number;
};

type IntentType = IntentObject[];

type DefaultsState = {
  name: string;
  image: string;
  listBox: string;
  placeholder: string;
  intent: IntentType;
  clientName: ClientNameProps;
};

export default defaults;
export { addClientInfo, addIntent, addClientName };
export type { IntentObject, IntentType, DefaultsState };
