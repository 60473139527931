const sunil2021_2 = {
  title: 'assets/custom/sunil/title.png',
  chattingBubbles: {
    resText: {
      background: '#F5F5F5',
      border: ' 1.5px solid rgba(196, 196, 196, 0.3)',
      color: '#111111',
    },
    action: {
      background: '#FFFFFF',
      border: '1.5px solid #111111',
      hoverColor: '#F5F5F5',
      color: '#111111',
    },
    relationButton: {
      background: {
        on: '#111111',
        off: '#FFFFFF',
      },
      border: '1.7px solid #111111',
      hover: {
        background: '#C9C9C9',
        color: '#fff',
      },
      active: {
        background: '#111111',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#111111',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#111111',
      color: '#FFFFFF',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1px solid #111111',
        off: '1px solid #F3F3F3',
      },
    },
    title: {
      color: '#111111',
    },
    swiper: {
      color: '#111111',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#111111',
      color: '#FFFFFF',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #111111',
        off: '1.5px solid #EAEAEA',
      },
      hover: '1.5px solid #111111',
    },
    text: {
      background: '#FFFFFF',
      color: '#111111',
      letterSpacing: '-0.13em',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#FFFFFF',
      border: '1px solid #EDEDED',
    },
    button: {
      background: {
        on: '#111111',
        off: '#FAFAFA',
      },
      border: {
        on: '#111111',
        off: '#EDEDED',
      },
      font: {
        on: '#FFFFFF',
        off: '#111111',
      },
    },
  },
  relation: {
    button: {
      background: {
        on: '#111111',
        off: '#FFFFFF',
      },
      border: '1.5px solid #111111',
      hover: {
        background: '#C9C9C9',
      },
      active: {
        background: '#111111',
      },
      color: {
        on: '#FFFFFF',
        off: '#111111',
      },
    },
    listbutton: {
      background: '#FFFFFF',
      border: '1.5px solid #111111',
      hoverBackground: '#F5F5F5',
    },
    listboxtext: {
      color: '#111111',
    },
    listele: {
      backgroundColor: {
        odd: '#E4E4E4',
        even: '#FFFFFF',
      },
      color: '#111111',
      hoverBackground: '#C4C4C4',
    },
  },
  bookmark: {
    wrap: {
      background: 'white',
    },
    button: {
      background: 'white',
    },
  },
  header: {
    wrap: {
      background: 'white',
    },
    company: {
      background: 'white',
    },
    title: {
      color: '#111111',
    },
  },
  input: {
    wrap: {
      background: 'white',
    },
    postWrap: {
      border: {
        on: '1px solid #111111',
        off: '1px solid #E7E7E7',
      },
    },
    sendbutton: {
      background: 'black',
    },
  },
  visualpage: {
    header: {
      background: '#111111',
      color: '#FFFFFF',
    },
  },
  intent: {
    color: '#111111',
  },
};
export default sunil2021_2;
