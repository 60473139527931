const kfowork = {
  title: 'assets/custom/kfowork/title.svg',
  wrap: {
    background: '#ffffff',
  },
  chattingBubbles: {
    resText: {
      background: '#F5F5F5',
      border: ' 1px solid rgba(196, 196, 196, 0.3)',
      color: '#111111',
    },
    action: {
      background: '#ffffff',
      border: '1.5px solid rgba(50, 130, 255, 0.6)',
      hoverColor: '#E0ECFF',
      color: '#1E6DE9',
    },
    relationButton: {
      background: {
        on: '#D01700',
        off: '#ff361d',
      },
      border: '1.5px solid #d01700',
      hover: {
        background: '#D01700',
        color: '#fffff',
      },
      active: {
        background: '#D01700',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#ffffff',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#E0ECFF;',
      color: '#111111',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1.5px solid #3282FF',
        off: '1px solid #E9EAEF',
      },
      background: '#393939',
    },
    image: {
      background: '#ffffff',
    },
    swiper: {
      color: '#3282FF',
    },
    title: {
      color: '#1E6DE9',
      background: '#ffffff',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#3282FF',
      color: '#ffffff',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #3282FF',
        off: '1px solid #E9EAEF',
      },
      hover: '1.5px solid #3282FF',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      background: '#ffffff',
      color: '#1E6DE9',
      height: '24px',
    },
    imgEle: {
      height: '72px',
      background: '#ffffff',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#ffffff',
      border: '1px solid #EDEDED',
    },
    button: {
      background: {
        on: '#3282FF',
        off: '#FAFAFA;',
      },
      border: {
        on: 'none',
        off: '#E9EAEF',
      },
      font: {
        on: '#FFFFFF',
        off: '#3282FF',
      },
    },
    content: {
      color: '#383838',
    },
    image: {
      background: '#ffffff',
    },
  },
  relation: {
    button: {
      background: {
        on: '#1367E9',
        off: '#3282FF',
      },
      border: 'none',
      hover: {
        background: '#1367E9',
        color: '#ffffff',
      },
      active: {
        background: '#1367E9',
        color: '#ffffff',
      },
      color: {
        on: '#ffffff',
        off: '#ffffff',
      },
    },
    listbutton: {
      background: '#ffffff',
      border: '1.5px solid rgba(50, 130, 255, 0.6)',
      hoverBackground: '#E0ECFF',
    },
    listboxtext: {
      color: '#1E6DE9',
      fillColor: '#1E6DE9',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#ffffff',
        even: '#F4F4F4',
      },
      color: '#000000',
    },
    listmenu: {
      background: '#ffffff',
      border: '1px solid #E9EAEF',
    },
  },
  bookmark: {
    wrap: {
      background: '#ffffff',
    },
    button: {
      background: '#ffffff',
    },
    relation: {
      color: '#1E325F',
    },
  },
  header: {
    wrap: {
      background: '#3282FF',
    },
    company: {
      background: 'rgba(0,0,0,0)',
    },
    title: {
      color: 'white',
    },
  },
  chatting: {
    wrap: {
      background: '#ffffff',
    },
  },
  client: {
    title: {
      color: '#111111',
    },
  },
  input: {
    inputWrap: {
      background: '#ffffff',
    },
    wrap: {
      background: '#ffffff',
    },
    postWrap: {
      border: {
        on: '1px solid #3C5AAA',
        off: '1px solid #E9E9E9',
      },
      background: {
        on: '#ffffff',
        off: '#F9F9F9',
      },
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#F9F9F9',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17, 17, 17, 0.2) !important',
    },
    sendbutton: {
      background: '#3C5AAA',
    },
  },
  visualpage: {
    header: {
      background: '#3282FF',
      color: '#FFFFFF',
    },
    wrap: {
      background: '#ffffff',
    },
    contentsWrap: {
      width: 'calc(100% - 25px)',
      marginLeft: '0'
    },
    footer: {
      color: '#C4C4C4',
    },
  },
  intent: {
    color: '#3282FF',
  },
};
export default kfowork;
