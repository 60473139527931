const gjto = {
  title:true,
  chattingBubbles:{
    resText:{
      background:'#F5F5F5',
      border:' 1.5px solid rgba(196, 196, 196, 0.3)',
      color:'#111111'
    },
    action: {
      background:'#FFFFFF',
      border: '1.5px solid #D14527',
      hoverColor: '#FFE7E4',
      color:'#D14527'
    },
    relationButton:{
      background:{
        on:"#D14527",
        off:"#FFFFFF"
      },
      border: '1.7px solid #D14527',
      hover:{
        background:'#FFE7E4',
        color:'#D14527'
      },
      active:{
        background:'#D14527'
      },
      p:{
        color:{
          on:"#FFFFFF",
          off:"#D14527"
        }
      }
    }
  },
  user:{
    reqText:{
      background:'#D14527',
      color:'#FFFFFF'
    },
    reqTime:{
      color:'#111111'
    }
  },
  carousel:{
    ele:{
      border:{
        on:'1.5px solid #D14527',
        off:'1.5px solid #F3F3F3'
      }
    },
    title:{
      color:'#D14527'
    },
    swiper: {
      color: 'rgb(111, 54, 59)',
    },
    description:{
      color:'#383838'
    },
    button:{
      background:'#D14527',
      color:'#FFFFFF'
    }
  },
  checkerboard:{
    ele:{
      border:{
        on:'1.5px solid #D14527',
        off:'1.5px solid #EAEAEA'
      },
      hover:'1.5px solid #111111'
    },
    text:{
      background:'#FFFFFF',
      color:'#D14527',
    }
  },
  onecard:{
    wrap:{
      background:'#FFFFFF',
      border:'1px solid #EDEDED',
    },
    button:{
      background:{
        on:'#D14527',
        off:'#FAFAFA'
      },
      border:{
        on:'#D14527',
        off:'#EDEDED'
      },
      font:{
        on:'#FFFFFF',
        off:'#111111',
      }
    }
  },
  relation:{
    button:{
      background:{
        on:'#D14527',
        off:'#FFFFFF'
      },
      border:'1.7px solid rgba(235, 0, 41, 0.5)',
      hover:{
        background:'#FFE7E4',
      },
      active:{
        background:'#D14527',
      },
      color:{
        on:'#FFFFFF',
        off:'#D14527'
      }
    },
    listbutton:{
      background:'#FFFFFF',
      border:'1.5px solid #D14527',
      hoverBackground:'#D14527'
    },
    listboxtext:{
      color:'#D14527',
    },
    listele:{
      backgroundColor:{
        odd:'#F4F4F4',
        even:'#FFFFFF'
      },
      color:'#111111',
      hoverBackground:'#DADADA'
    }
  },
  bookmark:{
    wrap:{
      background:'white'
    },
    button:{
      background:'white'
    }
  },
  header:{
    wrap:{
      background:'#6F363B'
    },
    company:{
      background:'#6F363B'
    },
    title:{
      color:'#ffffff'
    }
    
  },
  input:{
    wrap:{
      background:'white'
    },
    postWrap:{
      border:{
        on:'1px solid #6F363B',
        off:'1px solid #E7E7E7',
      }
    },
    sendbutton:{
      background:'#6F363B'
    }
  },
  visualpage:{
    header:{
      background:'#6F363B',
      color:'#FFFFFF',
    }
  },
  intent:{
    color:'#D14527'
  }
}
export default gjto;