import styled, {keyframes} from "styled-components";
import { ReactComponent as Error } from '../../assets/Error.svg'

const ToastPopup = ()=>{
  return (
    <Wrap>
      <Error></Error>
      <NotoSans16px>해당 기능은 현재 준비중입니다.</NotoSans16px>
    </Wrap>
  )
}

export default ToastPopup

const fadeinout = keyframes`
  0%{ opacity:0}
  25%{ opacity:1}
  75%{ opacity:1}
  100%{opacity:0}
`

const Wrap = styled.div`
  top:40px;
  left: calc( 50% - 150px);
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 30px 14px 20px;
  width: calc(300px - 50px);
  height: calc(60px - 28px);
  animation: ${fadeinout} linear 1s;
  background: rgba(164, 164, 164, 0.8);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
`
const NotoSans16px = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;

  color: #FFFFFF;


  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 16px;
`