import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../modules';
import { CheckerBoardList } from '../../../modules/checkerBoard';
import {
  ClickDataFuction,
  ClientNameProps,
  StyleThemeProps,
} from '../../../types';

interface CheckerBoardProps {
  index: number;
  checkerBoard: CheckerBoardList;
  clickData: ClickDataFuction;
}

interface CheckerBoardEle {
  buttonName: string;
  image: string;
  id: string;
  clickData: ClickDataFuction;
}

const CheckerBoardElement = ({
  buttonName,
  image,
  id,
  clickData,
}: CheckerBoardEle) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const [clickState, setClickState] = useState(false);
  return (
    <CheckerBoardEleWrap
      clientname={clientName}
      state={clickState}
      onClick={() => {
        clickData('CheckerBoard', 'button', buttonName, id);
        setClickState(true);
      }}
    >
      <CheckerBoardImgWrap clientname={clientName}>
        <CheckerBoardImg src={image} clientname={clientName} />
      </CheckerBoardImgWrap>
      <CheckerBoardText clientname={clientName}>{buttonName}</CheckerBoardText>
    </CheckerBoardEleWrap>
  );
};
const CheckerBoard = ({
  index,
  checkerBoard,
  clickData,
}: CheckerBoardProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  return (
    <CheckerBoardWrap clientname={clientName}>
      {checkerBoard.map((ele, index) => {
        return (
          <CheckerBoardElement
            key={index}
            buttonName={ele.buttonName}
            image={ele.image}
            id={ele.id}
            clickData={clickData}
          />
        );
      })}
    </CheckerBoardWrap>
  );
};
const CheckerBoardWrap = styled.div<StyleThemeProps>`
  margin: 0px 18px;
  margin-top: 14px;
  display: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.wrap?.display || 'inline-flex;'};
  grid-template-columns: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.wrap?.gridTemplateColumns};
  flex-wrap: wrap;
  flex-direction: row;
  transform: translateZ(0);
`;
type CheckerBoardEleWrapProps = {
  state: boolean;
  clientname: string;
};
const CheckerBoardEleWrap = styled.button<CheckerBoardEleWrapProps>`
  width: 109px;
  height: 109px;
  border: ${({ state, clientname, theme }) =>
    state
      ? theme[clientname].checkerboard.ele.border.on
      : theme[clientname].checkerboard.ele.border.off};
  box-sizing: border-box;
  background: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.ele.background || '#ffffff'};
  display: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.ele.display};
  flex-direction: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.ele.flexDirection};
  /* Carousel_1 */
  padding: 0px;
  filter: drop-shadow(4px 6px 14px rgba(57, 57, 57, 0.08));
  border-radius: 24px;
  overflow: hidden;
  &:hover {
    border: ${({ clientname, theme }) => theme[clientname].checkerboard.hover};
  }
  margin-right: 6px;
  margin-bottom: 6px;
`;
const CheckerBoardImgWrap = styled.div<StyleThemeProps>`
  width: 100%;
  height: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.imgEle?.height || '72px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.imgEle?.background};
`;
const CheckerBoardImg = styled.img<StyleThemeProps>`
  background: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.img?.background || '#ffffff'};
  object-fit: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.img?.objectFit || 'contain'};
  max-width: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.img?.maxWidth};
  max-height: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.img?.maxHeight};
  border-radius: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.img?.borderRadius || '24px 24px 0px 0px'};
`;
const CheckerBoardText = styled.div<StyleThemeProps>`
  width: 100%;
  white-space: break-spaces;
  height: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.text?.height};
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: ${({ theme, clientname }) =>
    theme[clientname].checkerboard.text?.lineHeight || '24px'};
  background: ${({ theme, clientname }) =>
    theme[clientname].checkerboard.text.background};
  text-align: center;
  /* identical to box height, or 185% */
  letter-spacing: ${({ theme, clientname }) =>
    theme[clientname].checkerboard?.text?.letterSpacing || '-0.03em'};
  color: ${({ theme, clientname }) =>
    theme[clientname].checkerboard.text.color};
  display: ${({ theme, clientname }) =>
    theme[clientname].checkerboard.text?.display || 'block'};
  justify-content: ${({ theme, clientname }) =>
    theme[clientname].checkerboard.text?.justifyContent || 'none'};
  align-items: ${({ theme, clientname }) =>
    theme[clientname].checkerboard.text?.alignItems || 'none'};
`;

export default CheckerBoard;
