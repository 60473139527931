import styled from 'styled-components';
import { ReactComponent as Back } from '../../assets/Back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { closeVisualPage, addVisualPage } from '../../modules/visualPage';
import { Poppins12 } from '../../styles/fontStyls';
import { deleteTemporary } from '../../modules/temporary';
import { addLoaing, finishLoading, switchData } from '../../modules/chatting';
import { getTime } from '../../api/functions';
import { ClientNameProps, StyleThemeProps } from '../../types';

import Map from '../Map';

const VisualPage = () => {
  const heightRef = useRef<HTMLDivElement>(null);

  const [pageAnimation, setPageAnimation]: any = useState(null);
  const dispatch = useDispatch();
  const onCloseVisualPage = () => {
    dispatch(closeVisualPage());
  };
  const [scrollY, setScrollY] = useState(0);

  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { temporary } = useSelector((state: RootState) => ({
    temporary: state.temporary,
  }));
  const { visualPage } = useSelector((state: RootState) => ({
    visualPage: state.visualPage,
  }));
  const { chatting } = useSelector((state: RootState) => ({
    chatting: state.chatting,
  }));

  // const iframeRef = useRef<HTMLIFrameElement>(null);

  useLayoutEffect(() => {
    const updateWindowDimensions = () => {
      if (!heightRef.current) {
        return;
      }
      const newHeight = window.innerHeight * 0.01;
      heightRef.current.style.setProperty('--vh', `${newHeight}px`);
    };

    window.addEventListener('resize', updateWindowDimensions);

    updateWindowDimensions();

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    const animationDelay = clientName === 'investseoul' ? 3000 : 700;
    if (pageAnimation === false) setPageAnimation(null);
    if (visualPage.state)
      setTimeout(() => setPageAnimation(true), animationDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualPage.state]);

  useEffect(() => {
    const scrollEle = document.getElementById('visualPageScroll');

    if (scrollEle) {
      scrollEle.addEventListener('scroll', (e: any) => {
        setScrollY(e.target.scrollTop);
      });
    }
  }, [visualPage]);

  const handleCloseVisualPage = () => {
    if (temporary.length > 0) {
      setTimeout(() => {
        dispatch(addLoaing(getTime()));
      }, 1000);
      temporary.map((ele: any, idx: number) => {
        if (idx === temporary.length - 1) {
          return setTimeout(() => {
            dispatch(finishLoading(ele, chatting.length));
          }, 3500 * (idx + 1));
        }
        return setTimeout(() => {
          return dispatch(switchData(ele, chatting.length));
        }, 3500 * (idx + 1));
      });
    }
    dispatch(deleteTemporary());
    setPageAnimation(false);
    // if (!iframeRef.current) return;

    // dispatch(addVisualPage({ source: [], state: false }));

    setTimeout(() => {
      const scrollEle = document.getElementById('visualPageScroll');
      scrollEle && scrollY !== 0 && scrollEle.scrollTo(0, 0);
      onCloseVisualPage();
    }, 1000);
  };

  //<IframWrap> </IframWrap>
  const render = () => {
    if (visualPage.source[0]) {
      if (visualPage.source[0].type === 'webpage') {
        if (visualPage.source[0].value.url) {
          return (
            <Ifram
              src={visualPage.source[0].value.url}
              width={'100%'}
              height={'100%'}
            ></Ifram>
          );
        }
      } else {
        return (
          <VisualPageMapWrap>
            {visualPage.source.map((ele: MapEleProps, index: number) => {
              if (ele.type === 'image') {
                return (
                  <Image
                    src={ele.value.url}
                    key={index}
                    alt={'비주얼 페이지 이미지'}
                  ></Image>
                );
              } else if (ele.type === 'youtube') {
                return (
                  <YoutubeIframeWrap key={index}>
                    <iframe
                      title="youtube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      src={`https://www.youtube.com/embed/${ele.value.youtube_id}`}
                      // ref={iframeRef}
                    ></iframe>
                  </YoutubeIframeWrap>
                );
              } else if (ele.type === 'map') {
                return (
                  <Map
                    key={index}
                    address={ele.value.address}
                    place_name={ele.value.place_name}
                    pageAnimation={pageAnimation}
                  />
                );
              } else {
                return (
                  <InnerDiv
                    key={index}
                    dangerouslySetInnerHTML={{ __html: ele.value.content }}
                  ></InnerDiv>
                );
              }
            })}
          </VisualPageMapWrap>
        );
      }
    }
  };
  type MapEleProps = {
    type: string;
    value: any;
  };

  return (
    <>
      <VisualPageOutside
        animationState={pageAnimation}
        clientname={clientName}
        state={visualPage.state}
        style={{ height: '100%' }}
      >
        <VisualPageWrap
          id="visualPageWrapScroll"
          state={pageAnimation}
          clientname={clientName}
          ref={heightRef}
        >
          <Header clientname={clientName}>
            <Back onClick={handleCloseVisualPage} stroke="white" />
            <p>Dual Page</p>
          </Header>
          {/* <ContentsWrap> */}
          <Wrap clientname={clientName}>
            <ContentsWrap id="visualPageScroll" clientname={clientName}>
              {render()}
            </ContentsWrap>

            <VisualPageFooter clientname={clientName}>
              Powered by Dual Talk
              <br />
              Copyright © 2021 by Xinapse. All rights reserved.
            </VisualPageFooter>
            {/* </ContentsWrap> */}
          </Wrap>
        </VisualPageWrap>
      </VisualPageOutside>
    </>
  );
};

export default VisualPage;
type OutSideProps = {
  state: boolean;
  animationState: boolean;
  clientname: string;
};
type VisualPageProps = {
  state: boolean;
  clientname: string;
};
const VisualPageOutside = styled.div<OutSideProps>`
  display: ${(props) =>
    props.animationState === null || !props.state ? 'none' : ''};
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${({ clientname, theme }) =>
    theme[clientname].visualpage?.outside?.background || 'rgba(0, 0, 0, 0.8)'};
  opacity: ${(props) => (props.state ? '1' : '0')};
  font-family: 'Noto Sans KR', Poppins;
`;

const VisualPageWrap = styled.div<VisualPageProps>`
  width: ${({ clientname, theme }) =>
    theme[clientname].visualpage?.wrap?.width || 'calc(100% - 30px)'};
  height: calc(var(--vh, 1vh) * 100);
  animation-duration: 1500ms;
  animation-name: ${(props) =>
    props.state ? 'visualPageSlideShow' : 'visualPageSlideHide'};
  visibility: ${(props) => (props.state === null ? 'hidden' : 'visible')};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  border-radius: 0px 36px 0px 0px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @keyframes visualPageSlideShow {
    from {
      transform: translateX(-375px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes visualPageSlideHide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-375px);
    }
  }
`;
const Header = styled.div<StyleThemeProps>`
  width: calc(100% - 40px);
  height: 58px;
  background: ${({ theme, clientname }) =>
    theme[clientname].visualpage.header.background};
  display: flex;
  align-items: center;
  padding-left: 20px;
  /* position: absolute;
  top:0px; */
  border-radius: 0px 36px 0px 0px;
  p {
    margin: 0px;
    margin-left: 16px;
    font-family: Poppins, Noto Sans KR;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    letter-spacing: -0.03em;
    color: ${({ theme, clientname }) =>
      theme[clientname].visualpage.header.color};
  }
  svg {
    stroke: ${({ theme, clientname }) =>
      theme[clientname].visualpage.header?.stroke || '#ffffff'};
  }
`;

const Wrap = styled.div<StyleThemeProps>`
  width: calc(100% - 20px);
  height: calc(100% - 58px);
  /* height: calc(100vh); */
  background-color: ${({ theme, clientname }) =>
    theme[clientname].visualpage?.wrap?.background || '#ffffff'};
  border-top: ${({ theme, clientname }) =>
    theme[clientname].visualpage?.wrap?.borderTop};
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    font-family: 'Noto Sans KR';
  }
`;
const Ifram = styled.iframe`
  border: 0px;
`;
const VisualPageFooter = styled.div<StyleThemeProps>`
  ${Poppins12}
  /* or 150% */
  letter-spacing: -0.03em;
  color: ${({ theme, clientname }) =>
    theme[clientname].visualpage?.footer?.color || '#c4c4c4'};
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 17px 18px;
`;

const VisualPageMapWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ContentsWrap = styled.div<StyleThemeProps>`
  width: ${({ theme, clientname }) =>
    theme[clientname].visualpage?.contentsWrap?.width || 'calc(100% - 48px)'};
  height: calc(100%);
  margin-top: 24px;
  overflow: scroll;
  margin-left: ${({ theme, clientname }) =>
    theme[clientname].visualpage?.contentsWrap?.marginLeft || '0'};
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const YoutubeIframeWrap = styled.div`
  position: relative;
  min-width: 260px;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
  iframe {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
`;
const Image = styled.img`
  margin: 10px 0px;
  width: 100%;
  object-fit: cover;
`;
const InnerDiv = styled.div`
  margin: 10px 0px;
  img {
    object-fit: cover;
  }
`;
