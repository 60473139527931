import { AnyAction } from "redux";
import { DisplayState } from "../types";

const MOBILE = 'display/Mobile';
const DESKTOP = 'display/Desktop';

const convertMobile = ()=>({
  type:MOBILE,
});

const converDesktop = ()=>({
  type:DESKTOP
});

const initialState:DisplayState = {
  state: 'Mobile'
};

const display = (
  state:DisplayState=initialState,
  action: AnyAction
)=>{
  switch(action.type){
    case MOBILE:
      return {
        state:'Mobile'
      }
    case DESKTOP:
      return {
        state:'Desktop'
      }
    default:
      return state
  }
};

export default display;

export {
  convertMobile,
  converDesktop,
};