import { AnyAction } from "redux";

const ADDRELATION = 'relation/AddRelation';

const addRelation = (list:RelationList)=>({
  type:ADDRELATION,
  list
})

const initialState:RelationState = [];

const relation = (
  state:RelationState = initialState,
  action:AnyAction
)=>{
  let newState = state.slice();
  switch(action.type){
    case ADDRELATION:
      newState.push(action.list)
      return newState
    default:
      return state
  }
}

export default relation;
export{
  addRelation
}
export type{
  RelationObject,
  RelationList,
  RelationState
}

type RelationObject = {
  id:string;
  name:string;
  type:string;
  value:string;
}
type RelationList = RelationObject[];
type RelationState = RelationList[];