import { AnyAction } from "redux";

const ADDCAROUSEL = 'carousel/AddCarousel';

const addCarousel = (list: CarouselList)=>({
  type:ADDCAROUSEL,
  list
})

const initialState:CarouselState = [];

const carousel =(
  state:CarouselState = initialState,
  action:AnyAction
)=>{
  let newState = state.slice()
  switch(action.type){
    case ADDCAROUSEL:
      newState.push(action.list);
      return newState;
    default:
      return state;
  }
}

type CarouselObject = {
	buttonName:string;
	id:string;
	content:string; // 해당 내용은 HTML
	title:string; // 해당 내용은 HTML
	image:string;
  type:string;
}
type CarouselList = CarouselObject[];
type CarouselState = CarouselList[];

export default carousel;
export {
  addCarousel
}
export type{
  CarouselObject,
  CarouselList,
  CarouselState
}