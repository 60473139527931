import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../modules';
import { ClientNameProps, StyleThemeProps } from '../../../types';

interface UserProps {
  id: number;
  time: string;
  index: number;
}

const User = ({ id, time, index }: UserProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { text } = useSelector((state: RootState) => ({
    text: state.userChatting[id],
  }));
  return (
    <Wrap key={index}>
      <Request>
        <ReqText clientname={clientName}>{text === '  ' ? '' : text}</ReqText>
        <ReqTime clientname={clientName}>{time}</ReqTime>
      </Request>
    </Wrap>
  );
};

export default User;
const Wrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

const Request = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 15px 18px;
  max-width: calc(80% - 18px);
  position: relative;
`;

const ReqText = styled.p<StyleThemeProps>`
  background: ${({ theme, clientname }) =>
    theme[clientname].user.reqText.background};
  border-radius: 24px;
  padding: 10px 16px;
  font-family: Poppins, Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: left;
  color: ${({ theme, clientname }) => theme[clientname].user.reqText.color};
  margin: 0;
`;
const ReqTime = styled.p<StyleThemeProps>`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.025em;
  position: absolute;
  bottom: -17px;
  right: 0;
  width: 100px;
  text-align: right;
  margin: 0;
  color: ${({ theme, clientname }) => theme[clientname].user.reqTime.color};
  opacity: 0.4;
`;
