import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../modules';
import { ClientNameProps, StyleThemeProps } from '../../types';
import { setModalData } from '../../modules/modal';

interface ClientProps {
  time: string;
  index: number;
}

const Client = ({ time }: ClientProps) => {
  const { defaults } = useSelector((state: RootState) => ({
    defaults: state.defaults,
  }));
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const dispatch = useDispatch();
  const imageModalOpen = () =>
    dispatch(setModalData({ state: 'open', url: defaults.image }));

  return (
    <ClientWrap>
      <ClientImage src={defaults.image} onClick={imageModalOpen} />
      <ClientTitle clientname={clientName}>
        <h4>{defaults.name}</h4>
        <p>{time}</p>
      </ClientTitle>
    </ClientWrap>
  );
};

export default Client;

const ClientWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-left: 18px;
`;

const ClientImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 24px;
  margin-right: 12px;
`;
const ClientTitle = styled.div<StyleThemeProps>`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  h4 {
    font-family: 'Noto Sans KR', Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    padding: 0px;
    margin: 0px;
    /* identical to box height, or 137% */
    letter-spacing: -0.025em;
    color: ${({ theme, clientname }) =>
      theme[clientname].client?.title.color || '#111111'};
  }
  p {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
    margin: 0px;
    /* identical to box height, or 133% */
    letter-spacing: -0.025em;
    color: ${({ theme, clientname }) =>
      theme[clientname].client?.title.color || '#111111'};
    opacity: 0.4;
  }
`;
