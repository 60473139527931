const sample1 = {
  title: 'assets/custom/sample1/title.svg',
  wrap: {
    background: '#ffffff',
  },
  chattingBubbles: {
    resText: {
      background: '#F5F5F5',
      border: ' 1px solid rgba(196, 196, 196, 0.3)',
      color: '#111111',
    },
    action: {
      background: '#ffffff',
      border: '1.5px solid #00A1E2',
      hoverColor: '#F3FCFF',
      color: '#00A1E2',
    },
    relationButton: {
      background: {
        on: '#D01700',
        off: '#ff361d',
      },
      border: '1.5px solid #d01700',
      hover: {
        background: '#D01700',
        color: '#fffff',
      },
      active: {
        background: '#D01700',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#ffffff',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#004EA2',
      color: '#ffffff',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1.5px solid #00A1E2',
        off: '1px solid rgba(64, 64, 64, 0.3)',
      },
      background: {
        on: '#F3FCFF',
        off: '#ffffff',
      },
    },
    image: {
      background: '#ffffff',
    },
    swiper: {
      color: 'rgb(195, 42, 36)',
    },
    title: {
      color: '#004EA2',
      background: '#ffffff',
    },
    description: {
      color: '#383838',
    },
    button: {
      background:
        'linear-gradient(90deg, #8BC452 0%, #00AA7D 34.48%, #008CCD 68.97%, #004EA2 100%)',
      color: '#ffffff',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #00A1E2',
        off: '1px solid #EAEAEA',
      },
      hover: '1.5px solid #111111',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      background: '#ffffff',
      color: '#004EA2',
      height: '24px',
    },
    imgEle: {
      height: '72px',
      background: '#ffffff',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#ffffff',
      border: '1px solid #e5e5e5',
    },
    button: {
      background: {
        on: '#00A1E2',
        off: '#F3FCFF',
      },
      border: {
        on: '#00A1E2',
        off: '#DDF1F8',
      },
      font: {
        on: '#FFFFFF',
        off: '#004EA2',
      },
    },
    content: {
      color: '#383838',
    },
    image: {
      background: '#ffffff',
    },
  },
  relation: {
    button: {
      background: {
        on: '#00A1E2',
        off: '#ffffff',
      },
      border: '1.5px solid #00A1E2',
      hover: {
        background: '#F3FCFF',
        color: '#00A1E2',
      },
      active: {
        background: '#00A1E2',
        color: '#ffffff',
      },
      color: {
        on: '#ffffff',
        off: '#00A1E2',
      },
    },
    listbutton: {
      background: '#ffffff',
      border: '1.5px solid #00A1E2',
      hoverBackground: '#F3FCFF',
    },
    listboxtext: {
      color: '#004EA2',
      fillColor: '#00A1E2',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#ffffff',
        even: '#F0F0F6',
      },
      color: '#111111',
      hoverBackground: '#F0F0F6',
    },
    listmenu: {
      background: '#ffffff',
      border: '1.5px solid #004EA2',
    },
  },
  bookmark: {
    wrap: {
      background: '#ffffff',
    },
    button: {
      background: '#ffffff',
    },
    relation: {
      color: '#3F2B2B',
    },
  },
  header: {
    wrap: {
      background:
        'linear-gradient(90deg, #8BC452 0%, #00AA7D 34.48%, #008CCD 68.97%, #004EA2 100%)',
    },
    company: {
      background: 'rgba(0,0,0,0)',
    },
    title: {
      color: 'white',
    },
    title2: {
      color: '#FFD248',
    },
    title3: {
      color: 'white',
    },
  },
  chatting: {
    wrap: {
      background: '#ffffff',
    },
  },
  client: {
    title: {
      color: '#111111',
    },
  },
  input: {
    inputWrap: {
      background: '#ffffff',
    },
    wrap: {
      background: '#ffffff',
    },
    postWrap: {
      border: {
        on: '1px solid #004EA2',
        off: '1px solid rgba(63, 43, 43, 0.1)',
      },
      background: {
        on: '#ffffff',
        off: '#F9F9FD',
      },
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#F9F9FD',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17,17,17,0.2)',
    },
    sendbutton: {
      background: '#004EA2',
    },
  },
  visualpage: {
    header: {
      background:
        'linear-gradient(90deg, #8BC452 0%, #00AA7D 34.48%, #008CCD 68.97%, #004EA2 100%)',
      color: '#FFFFFF',
    },
    wrap: {
      background: '#ffffff',
    },
    footer: {
      color: '#C4C4C4',
    },
  },
  intent: {
    color: '#004EA2',
  },
};
export default sample1;
