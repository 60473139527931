const ADDSEARCHINTENT = 'intent/AddSearchIntent';

const addSearchIntent = (list: IntentType) => ({
  type: ADDSEARCHINTENT,
  list,
});

const initailState: IntentType = [];

const intent = (state: IntentType = initailState, action: Actions) => {
  switch (action.type) {
    case ADDSEARCHINTENT:
      return action.list;

    default:
      return state;
  }
};

type Actions = ReturnType<typeof addSearchIntent>;
type IntentType = any[];

export type { IntentType, Actions };

export default intent;
export { addSearchIntent };
