import {css} from "styled-components";
export const NotoSans12 = css`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`
export const NotoSans13 = css`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
`
export const NotoSans14 = css`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`
export const NotoSans15 = css`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`
export const NotoSans16 = css`
  font-family: Poppins, Noto Sans KR;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
`
export const NotoSans17 = css`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
`
export const NotoSans18 =css`
  font-family: Noto Sans KR Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`
export const NotoSans20 = css`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`
export const NotoSans40 = css`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
` 
export const Poppins11 = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
`
export const Poppins12 = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`
export const PoppinsAndNotoSans14 = css`
  font-family: Poppins, Noto Sans KR;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
`