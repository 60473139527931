const xcrew = {
  title: 'assets/custom/xcrew/title.svg',
  wrap: {
    background: '#141414',
  },
  chattingBubbles: {
    resText: {
      background: '#353535',
      border: ' 1.5px solid rgba(64, 64, 64, 0.3)',
      color: 'rgba(255, 255, 255, 0.8)',
    },
    action: {
      background: '#ff361d',
      border: '1.5px solid #ff361d',
      hoverColor: '#D01700',
      color: '#ffffff',
    },
    relationButton: {
      background: {
        on: '#D01700',
        off: '#ff361d',
      },
      border: '1.5px solid #d01700',
      hover: {
        background: '#D01700',
        color: '#fffff',
      },
      active: {
        background: '#D01700',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#ffffff',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#7D1001',
      color: 'rgba(255, 255, 255, 0.9)',
    },
    reqTime: {
      color: 'white',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1px solid #ff361d',
        off: '1px solid rgba(64, 64, 64, 0.3)',
      },
      background: '#393939',
    },
    title: {
      color: '#ff361d',
      background: '#393939',
    },
    swiper: {
      color: '#ffffff',
    },
    description: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    button: {
      background: '#ff361d',
      color: '#FFFFFF',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #ff361d',
        off: '1.5px solid rgba(64, 64, 64, 0.3)',
      },
      hover: '1.5px solid #111111',
      background: '#353535',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      background: '#353535',
      color: '#ffffff',
      height: '24px',
    },
    imgEle: {
      height: '72px',
      background: '#353535',
    },
    img: {
      background: '#353535',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
    },
  },
  onecard: {
    wrap: {
      background: '#353535',
      border: '1px solid rgba(64, 64, 64, 0.3)',
    },
    button: {
      background: {
        on: '#ff361d',
        off: 'rgba(58, 58, 58, 0.5)',
      },
      border: {
        on: '#111111',
        off: '#EDEDED',
      },
      font: {
        on: '#FFFFFF',
        off: 'rgba(255, 255, 255, 0.7)',
      },
    },
    content: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    image: {
      background: '#494949',
    },
  },
  relation: {
    button: {
      background: {
        on: '#ff361d',
        off: '#ff361d',
      },
      border: '1px solid #ff361d',
      hover: {
        background: '#d01700',
      },
      active: {
        background: '#d01700',
      },
      color: {
        on: '#FFFFFF',
        off: '#FFFFFF',
      },
    },
    listbutton: {
      background: '#141414',
      border: '1.5px solid #ff361d',
      hoverBackground: '#F5F5F5',
    },
    listboxtext: {
      color: 'rgba(255, 255, 255, 0.8)',
      fillColor: '#ff361d',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#353535',
        even: '#595959',
      },
      color: 'rgba(255, 255, 255, 0.88)',
      hoverBackground: '#DADADA',
    },
    listmenu: {
      background: '#353535',
      border: '1px solid rgba(64, 64, 64 0.3)',
    },
  },
  bookmark: {
    wrap: {
      background: '#000000',
    },
    button: {
      background: '#000000',
    },
    relation: {
      color: 'hsla(0,0%,100%,.8)',
    },
  },
  header: {
    wrap: {
      background: 'black',
    },
    company: {
      background: 'black',
    },
    title: {
      color: 'white',
    },
  },
  chatting: {
    wrap: {
      background: '#141414',
      borderTop: '1.5px solid #ff361d',
    },
  },
  client: {
    title: {
      color: 'white',
    },
  },
  input: {
    inputWrap: {
      background: '#141414',
    },
    wrap: {
      background: '#000000',
    },
    postWrap: {
      border: {
        on: '1px solid #ff361d',
        off: '1px solid #5a5a5a',
      },
      background: {
        on: '#353535',
        off: '#141414',
      },
    },
    textInput: {
      background: {
        on: '#353535',
        off: '#141414',
      },
      color: {
        on: '#ffffff',
        off: 'rgba(255, 255, 255, 0.3)',
      },
    },
    sendbutton: {
      background: '#ff361d',
    },
  },
  visualpage: {
    header: {
      background: '#000000',
      color: '#FFFFFF',
    },
    outside: {
      // #4b4b4b to rgba convert
      background: 'rgba(75, 75, 75, 0.7)',
    },
    wrap: {
      background: '#141414',
      borderTop: '1.5px solid #ff361d',
    },
    footer: {
      // #c4c4c4 to rgba convert
      color: 'rgba(196, 196, 196, 0.4)',
    },
  },
  intent: {
    color: '#ff361d',
  },
};
export default xcrew;
