import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../modules';

const Greeting = () => {
  const { clientName } = useSelector((state: RootState) => ({
    clientName: state.defaults.clientName,
  }));
  const { greetingData } = useSelector((state: RootState) => ({
    greetingData: state.greeting,
  }));

  const [hideGreeting, setHideGreeting] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHideGreeting(false);
    }, greetingData.time);
  }, [greetingData]);

  return (
    <>
      {hideGreeting && (
        <Wrap>
          <video autoPlay muted playsInline>
            <source
              src={`https://chatbot-front.s3.ap-northeast-2.amazonaws.com/${clientName}/greeting/Greeting.mp4`}
              type="video/mp4"
            />
          </video>
        </Wrap>
      )}
    </>
  );
};

export default Greeting;

const Wrap = styled.div`
  width: 100%;
  height: calc(100vh - 82px - 58px);
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    width: 80%;
    height: 80%;
  }
`;
