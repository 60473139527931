const GREETINGDATA = 'greeting/GreetingTime';

export const setGreetingData = ({ time }: GreetingType) => ({
  type: GREETINGDATA,
  time,
});

interface GreetingType {
  time: number;
}

const initailState: GreetingType = {
  time: 6000,
};

const greeting = (state: GreetingType = initailState, action: Actions) => {
  switch (action.type) {
    case GREETINGDATA:
      return { ...state, time: action.time };

    default:
      return state;
  }
};

type GreetingStateActions = ReturnType<typeof setGreetingData>;

export type { GreetingType };
export type Actions = GreetingStateActions;

export default greeting;
