import styled from 'styled-components';
import { useEffect } from 'react';
// import styled from 'styled-components';
const { kakao } = window as any;

interface MapProps {
  address: string;
  place_name: string;
  pageAnimation: Boolean;
}

const Map = ({ address, place_name, pageAnimation }: MapProps) => {
  useEffect(() => {
    kakao.maps.load(() => {
      let container = document.getElementById('map');
      let options = {
        center: new kakao.maps.LatLng(0, 0),
        level: 3,
      };
      let map = new kakao.maps.Map(container, options);

      let geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(address, (result: any, status: any) => {
        if (status === kakao.maps.services.Status.OK) {
          const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
          const marker = new kakao.maps.Marker({
            map: map,
            position: coords,
            clickable: true,
          });
          var infowindow = new kakao.maps.InfoWindow({
            content:
              '<div style="width:150px;text-align:center;padding:6px 0;">검색하기</div>',
          });
          infowindow.open(map, marker);
          map.setCenter(coords);
          kakao.maps.event.addListener(marker, 'click', () => {
            window.open(`https://map.kakao.com/link/search/${place_name}`);
          });
        }
      });
    });
  }, [address, place_name, pageAnimation]);
  return (
    // <Wrap>
    <>
      <div id={'map'} style={{ width: '100%', height: '450px' }}></div>
      <Description>
        <DescriptionNameP>{place_name} </DescriptionNameP>
        <DescriptionAddressP>{address}</DescriptionAddressP>
      </Description>
    </>
  );
};

const Description = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
`;

const DescriptionNameP = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 10px 5px 10px 0;
`;

const DescriptionAddressP = styled.p`
  font-size: 12px;
  margin: 10px 0 10px 0;
`;

export default Map;
