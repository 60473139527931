import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Back } from '../../assets/Back.svg';
import { ReactComponent as Logo } from '../../assets/menu/logo.svg';
import { ReactComponent as Text } from '../../assets/menu/formatSize.svg';
import { ReactComponent as Dot } from '../../assets/menu/Dot.svg';
import { ReactComponent as DotPoint } from '../../assets/menu/DotPoint.svg';
import { ReactComponent as Home } from '../../assets/menu/home.svg';
import { ReactComponent as Share } from '../../assets/menu/share.svg';
import { ReactComponent as Quiz } from '../../assets/menu/quiz.svg';
import {
  NotoSans13,
  NotoSans15,
  NotoSans18,
  NotoSans20,
  Poppins11,
} from '../../styles/fontStyls';

interface MenuProps {
  state: boolean;
  setState: (state: boolean) => void;
}

const Menu = ({ state, setState }: MenuProps) => {
  const [animtion, setAnimation] = useState(false);
  const [radioState, setRadioState] = useState('medium');
  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setAnimation(true);
      }, 100);
    }
  }, [state]);
  return (
    <Wrap state={state}>
      <ContentsWrap animation={animtion}>
        <HeaderWrap>
          <ButtonWrap>
            <Back
              stroke="white"
              onClick={() => {
                setAnimation(false);
                setTimeout(() => {
                  setState(false);
                }, 1000);
              }}
            ></Back>
            <MenuTitle>Menu</MenuTitle>
          </ButtonWrap>
          <LogoWrap>
            <Logo></Logo>
          </LogoWrap>
        </HeaderWrap>
        <ContentWrap>
          <MainWrap>
            <MainEle
              onClick={() => {
                window.location.reload();
              }}
            >
              <Home></Home>
              <MainEleText>처음으로</MainEleText>
            </MainEle>
            <MainEle onClick={() => {}}>
              <Share></Share>
              <Tempcomp></Tempcomp> <MainEleText>공유하기</MainEleText>
            </MainEle>
            <MainEle onClick={() => {}}>
              <Quiz></Quiz> <MainEleText>견적문의</MainEleText>
            </MainEle>
          </MainWrap>
          <FooterWrap>
            <SelectWrap>
              <SelectTitle>
                <Text></Text>
                <TextSize>글자 크기</TextSize>
              </SelectTitle>
              <SelectTypes>
                <SelectEles>
                  <SelectEle
                    onClick={() => {
                      setRadioState('small');
                    }}
                  >
                    {radioState === 'small' ? (
                      <DotPoint></DotPoint>
                    ) : (
                      <Dot></Dot>
                    )}
                  </SelectEle>
                  <Line />
                  <SelectEle
                    onClick={() => {
                      setRadioState('medium');
                    }}
                  >
                    {radioState === 'medium' ? (
                      <DotPoint></DotPoint>
                    ) : (
                      <Dot></Dot>
                    )}
                  </SelectEle>
                  <Line />
                  <SelectEle
                    onClick={() => {
                      setRadioState('large');
                    }}
                  >
                    {radioState === 'large' ? (
                      <DotPoint></DotPoint>
                    ) : (
                      <Dot></Dot>
                    )}
                  </SelectEle>
                </SelectEles>
                <SelectOptionTextWrap>
                  <SelectOptionTextSmall>작게</SelectOptionTextSmall>
                  <SelectOptionTextMidium>보통</SelectOptionTextMidium>
                  <SelectOptionTextLarge>크게</SelectOptionTextLarge>
                </SelectOptionTextWrap>
              </SelectTypes>
            </SelectWrap>
            <FooterText>
              <div>Copyright © 2021 by Xinapse.</div>
              <div>All rights reserved.</div>
            </FooterText>
          </FooterWrap>
        </ContentWrap>
      </ContentsWrap>
    </Wrap>
  );
};

export default Menu;

interface WarpProps {
  state: boolean;
}

const Wrap = styled.div<WarpProps>`
  display: ${(props) => (props.state ? '' : 'none')};
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  margin-right: 95px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  transition: all linear 1s;
`;
interface ContentWrapProps {
  animation: boolean;
}
const ContentsWrap = styled.div<ContentWrapProps>`
  position: absolute;
  top: 0px;
  left: ${(props) => (props.animation ? '0px' : '-100%')};
  width: 280px;
  height: 100%;
  transition: all linear 1s;
`;
const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: #111111;
  border-radius: 0px 36px 0px 0px;
`;
const ButtonWrap = styled.div`
  width: 280px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 20px;
`;
const MenuTitle = styled.div`
  ${NotoSans18}
  margin-left: 16px;
  color: #ffffff;
`;
const LogoWrap = styled.div`
  margin-left: 24px;
  margin: 24px;
`;
const ContentWrap = styled.div`
  height: calc(100% - 136px);
  width: 100%;
  background: #ffffff;
`;
const MainWrap = styled.div`
  width: 100%;
  height: calc(100% - 230px - 20px);
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;
const MainEle = styled.div`
  width: calc(100% - 24px);
  padding-left: 24px;
  height: 56px;
  display: flex;
  align-items: center;
`;
const MainEleText = styled.div`
  ${NotoSans15}
  margin-left: 20px;
`;
const FooterWrap = styled.div`
  width: 100%;
  height: 230px;
  background: rgba(196, 196, 196, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SelectWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SelectTitle = styled.div`
  width: 100%;
  margin-left: 24px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SelectTypes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 29px;
  margin-bottom: 18px;
`;
const SelectEles = styled.div`
  width: 194px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const SelectEle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #c4c4c4;
`;
const SelectOptionTextWrap = styled.div`
  width: 205px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SelectOptionTextSmall = styled.div`
  ${NotoSans13}
  width: 30px;
  text-align: center;
  letter-spacing: -0.02em;
`;
const SelectOptionTextMidium = styled.div`
  ${NotoSans15}
  text-align: center;
  letter-spacing: -0.02em;
`;
const SelectOptionTextLarge = styled.div`
  ${NotoSans20}
  text-align: center;
  letter-spacing: -0.02em;
`;

const TextSize = styled.div`
  ${NotoSans15}
  margin-left:20px;
  letter-spacing: -0.02em;
  color: rgba(17, 17, 17, 0.5);
`;
const FooterText = styled.div`
  ${Poppins11}
  letter-spacing: -0.03em;
  color: #c4c4c4;
  margin-left: 18px;
  margin-bottom: 30px;
`;

const Tempcomp = styled.input`
  display: none;
`;
