import { AnyAction } from "redux";
import { ChattingType } from "../types";

const ADD = 'temporary/AddTemporary';
const DELETE = 'temporary/DeleteTemporary';

const addTemporary = (list:ChattingType)=>({
  type:ADD,
  list
})

const deleteTemporary = ()=>({
  type:DELETE
})

const initialState:ChattingType = [];

const temporary = (
  state:ChattingType = initialState,
  action: AnyAction
)=>{
  switch(action.type){
    case ADD:
      return action.list;
    case DELETE:
      return [];
    default:
      return state;
  }
}

export default temporary;

export{
  addTemporary,
  deleteTemporary
}