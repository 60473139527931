const ADDBOOKMARK = 'bookmark/AddBookMark';
const SWITCHSTATE = 'bookmark/SwitchState';
const OFFSTATE = 'bookmark/OffState';
const BUTTONSTATE = 'bookmark/ButtonState';
const BUTTONOFF = 'bookmark/ButtonOff';

export const addBookMark = (list: BookMarkObject[]) => ({
  type: ADDBOOKMARK,
  list,
});
export const switchState = () => ({ type: SWITCHSTATE });
export const offState = () => ({ type: OFFSTATE });
export const buttonState = () => ({ type: BUTTONSTATE });
export const buttonOff = () => ({ type: BUTTONOFF });
const initailState: BookMarkType = {
  bookmarkState: false,
  buttonState: false,
  data: [],
};

const bookmark = (state: BookMarkType = initailState, action: any) => {
  switch (action.type) {
    case ADDBOOKMARK:
      return {
        ...state,
        data: [...state.data, ...action.list],
      };
    case SWITCHSTATE:
      return {
        ...state,
        bookmarkState: true,
      };
    case OFFSTATE:
      return {
        ...state,
        bookmarkState: false,
      };
    case BUTTONSTATE:
      return {
        ...state,
        buttonState: !state.buttonState,
      };
    case BUTTONOFF:
      return {
        ...state,
        buttonState: false,
      };
    default:
      return state;
  }
};

export default bookmark;

export interface BookMarkObject {
  type: string;
  name: string;
  value: string;
  id: string;
}

export type BookMarkType = {
  bookmarkState: boolean;
  buttonState: boolean;
  data: BookMarkObject[];
};

type AddBookMarkAction = ReturnType<typeof addBookMark>;
type AddSwitchStateAction = ReturnType<typeof switchState>;

export type Actions = AddBookMarkAction | AddSwitchStateAction;
