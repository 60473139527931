const ADDUSERCHATTING = 'user/AddUserChatting';

const addUserBubbles = (message: string) => ({
  type: ADDUSERCHATTING,
  message,
});

const initialState: UserChattingState = [];

const userChatting = (
  state: UserChattingState = initialState,
  action: Actions
) => {
  let newState = state.slice();
  switch (action.type) {
    case ADDUSERCHATTING:
      newState.push(action.message);
      return newState;
    default:
      return state;
  }
};

type UserChattingState = string[];

type Actions = ReturnType<typeof addUserBubbles>;

export { addUserBubbles };

export type { UserChattingState, Actions };

export default userChatting;
