// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jardin: '자뎅',
  'jardin-test': '자뎅',
  mdtpcb: '메드트로닉',
  'mdtpcb-test': '메드트로닉',
  gjto: '광주관광재단 챗봇',
  'gjto-test': '광주관광재단 챗봇',
  nextstory: '넥스트스토리',
  'nextstory-test': '넥스트스토리',
  xcrew: '엑스크루',
  xcrewinfo: '엑스크루',
  kfo: '한국직업개발원',
  kfowork: '한국직업개발원',
  kfoedu: '한국직업개발원',
  'sunil2021-1': '송아',
  'sunil2021-2': '선하',
  smartig: '모두의 캠핑',
  sample1: '이재명은_소통합니다.',
  case1: '국민의 힘',
  'dept-bc': '환자 사후 관리 챗봇',
  pspfnd: 'PSP F&D',
  'pspfnd-test': 'PSP F&D',
  focu: '대한소방공제회',
  debc: '장애인기업종합지원센터',
  'debc-test': '장애인기업종합지원센터',
  investseoul: '서울투자청',
  kbstar: 'KB AI 금융비서',
  seocho: '서초구청 챗봇',
  visitjeju: '제주관광공사',
  'visitjeju-test': '제주관광공사',
  jejucom: '제주닷컴',
  'jejucom-test': '제주닷컴',
  sscb: '수성구청',
  'sscb-test': '수성구청',
  sample2: '테스트샘플',
  'sample2-test':'테스트샘플'
};
