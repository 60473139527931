const case1 = {
  title: 'assets/custom/case1/title.svg',
  wrap: {
    background: '#ffffff',
  },
  chattingBubbles: {
    resText: {
      background: '#F0F0F0',
      border: ' 1px solid rgba(196, 196, 196, 0.3)',
      color: '#111111',
    },
    action: {
      background: '#ffffff',
      border: '1.5px solid #E60024',
      hoverColor: '#FFF7F5',
      color: '#E60024',
    },
    relationButton: {
      background: {
        on: '#D01700',
        off: '#ff361d',
      },
      border: '1.5px solid #d01700',
      hover: {
        background: '#D01700',
        color: '#fffff',
      },
      active: {
        background: '#D01700',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#ffffff',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#112C56',
      color: '#ffffff',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1.5px solid #E60024',
        off: '1px solid rgba(64, 64, 64, 0.3)',
      },
      background: {
        on: '#FFF7F5',
        off: '#ffffff',
      },
    },
    image: {
      background: '#ffffff',
    },
    swiper: {
      color: 'rgb(195, 42, 36)',
    },
    title: {
      color: '#E60024',
      background: '#ffffff',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#E60024',
      color: '#ffffff',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #E60024',
        off: '1px solid #EAEAEA',
      },
      hover: '1.5px solid #111111',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      background: '#ffffff',
      color: '#E60024',
      height: '24px',
    },
    imgEle: {
      height: '72px',
      background: '#ffffff',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#ffffff',
      border: '1px solid #e5e5e5',
    },
    button: {
      background: {
        on: '#E60024',
        off: '#FFF7F5',
      },
      border: {
        on: '#E60024',
        off: '#DDF1F8',
      },
      font: {
        on: '#FFFFFF',
        off: '#E60024',
      },
    },
    content: {
      color: '#383838',
    },
    image: {
      background: '#ffffff',
    },
  },
  relation: {
    button: {
      background: {
        on: '#E60024',
        off: '#ffffff',
      },
      border: '1.5px solid #E60024',
      hover: {
        background: '#FFF7F5',
        color: '#E60024',
      },
      active: {
        background: '#E60024',
        color: '#ffffff',
      },
      color: {
        on: '#ffffff',
        off: '#E60024',
      },
    },
    listbutton: {
      background: '#ffffff',
      border: '1.5px solid #E60024',
      hoverBackground: '#FFF7F5',
    },
    listboxtext: {
      color: '#E60024',
      fillColor: '#E60024',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#ffffff',
        even: '#F4F4F4',
      },
      color: '#111111',
      hoverBackground: '#F4F4F4',
    },
    listmenu: {
      background: '#ffffff',
      border: '1.5px solid #E60024',
    },
  },
  bookmark: {
    wrap: {
      background: '#ffffff',
    },
    button: {
      background: '#ffffff',
    },
    relation: {
      color: '#111111',
    },
  },
  header: {
    wrap: {
      background: '#E60024',
    },
    company: {
      background: 'rgba(0,0,0,0)',
    },
    title: {
      fontSize: '16px',
      color: 'white',
    },
    title2: {
      fontSize: '16px',
      color: 'white',
    },
    title3: {
      fontSize: '16px',
      color: '#89D4FB',
    },
    titlespan: {
      fontSize: '16px',
      color: '#89D4FB',
    },
    img: {
      maxHeight: '30px',
    },
  },
  chatting: {
    wrap: {
      background: '#ffffff',
    },
  },
  client: {
    title: {
      color: '#111111',
    },
  },
  input: {
    inputWrap: {
      background: '#ffffff',
    },
    wrap: {
      background: '#ffffff',
    },
    postWrap: {
      border: {
        on: '1px solid #E60024',
        off: '1px solid rgba(63, 43, 43, 0.1)',
      },
      background: {
        on: '#ffffff',
        off: '#FFF7F5',
      },
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#FFF7F5',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17,17,17,0.2)',
    },
    sendbutton: {
      background: '#E60024',
    },
  },
  visualpage: {
    header: {
      background: '#E60024',
      color: '#FFFFFF',
    },
    wrap: {
      background: '#ffffff',
    },
    footer: {
      color: '#C4C4C4',
    },
  },
  intent: {
    color: '#E60024',
  },
};
export default case1;
