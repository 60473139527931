import { CarouselList } from '../modules/carousel';
import { ChatBubblesObject } from '../modules/chatBubbles';
import { ChattingType } from '../modules/chatting';
import { CheckerBoardList } from '../modules/checkerBoard';
import { OnecardList } from '../modules/oneCard';
import { RelationList } from '../modules/relation';
import { VisualPageState } from '../modules/visualPage';
import { ChatState, ResponseDataArrProps } from '../types';

const getTime = () => {
  let date = new Date();
  const hours = date.getHours();
  const hoursNew = hours > 12 ? hours - 12 : hours;
  const hoursZeroPlust = hoursNew < 10 ? `0${hoursNew}` : `${hoursNew}`;
  const minute = date.getMinutes();
  return `${hours > 12 ? '오후' : '오전'} ${hoursZeroPlust}:${
    minute < 10 ? `0${minute}` : minute
  }`;
};

interface NavigaeteCaseProps {
  response: any;
  chatState: ChatState;
  onAddChatBubble: (obj: ChatBubblesObject) => void;
  onAddCheckerBoard: (list: CheckerBoardList) => void;
  onAddCarousel: (list: CarouselList) => void;
  onAddOnecard: (list: OnecardList) => void;
  onAddRelation: (list: RelationList) => void;
  onAddVisualPage: (array: VisualPageState) => void;
  onRendingData: (
    list: ChattingType,
    index: number,
    time: string,
    visualPageState: boolean
  ) => void;
  onAddTemporary: (list: ChattingType) => void;
}

const responseParsing = (response: any) => {
  if(!response) return;
  
  const responseKeys = Object.keys(response);
  // relation check => checkerboard, carousel, one_card에 대한 리스폰스가 있는 지를 체크 하는 Boolean상태
  const relationCheck = responseKeys.reduce((acc: boolean, cur) => {
    if (
      cur === 'checkerboard' ||
      cur === 'carousel' ||
      cur === 'one_card' ||
      acc === true
    ) {
      return true;
    }
    return false;
  }, false);

  const contentsArr: ResponseDataArrProps[] = response.contents.reduce(
    (acc: Array<any>, element: any) => {
      const elementKeys = Object.keys(element);
      if (elementKeys.indexOf('negativeButton') !== -1) {
        return acc;
      }
      return [
        ...acc,
        {
          type: 'ChatBubbles',
          data: element,
        },
      ];
    },
    []
  );

  const autoIntentsArr: ResponseDataArrProps[] = response.contents.reduce(
    (acc: Array<any>, element: any) => {
      const elementKeys = Object.keys(element);
      if (elementKeys.indexOf('negativeButton') !== -1) {
        return [
          ...acc,
          {
            type: 'AutoIntents',
            data: element,
          },
        ];
      }
      return acc;
    },
    []
  );
  const relationArr: ResponseDataArrProps[] = responseKeys.reduce(
    (acc: Array<any>, cur: any) => {
      switch (cur) {
        case 'button':
          if (response[cur].values.length !== 0 && !relationCheck) {
            return [
              ...acc,
              {
                type: 'Relation',
                data: response[cur].values,
              },
            ];
          }
          return [...acc];
        case 'visual_page':
          if (response[cur].sources[0]) {
            return [
              ...acc,
              {
                type: 'VisualPage',
                data: response[cur].sources,
              },
            ];
          }
          return [...acc];
        case 'checkerboard':
          return [
            ...acc,
            {
              type: 'CheckerBoard',
              data: response[cur].values,
            },
          ];
        case 'carousel':
          return [
            ...acc,
            {
              type: 'Carousel',
              data: response[cur].values,
            },
          ];
        case 'one_card':
          return [
            ...acc,
            {
              type: 'OneCard',
              data: response[cur].values,
            },
          ];
        default:
          return [...acc];
      }
    },
    []
  );
  return [...contentsArr, ...relationArr, ...autoIntentsArr];
};

function getURLParams() {
  let params: any = {};
  window.location.search.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (str, key, value) => {
      params[key] = value;
      return '';
    }
  );
  return params;
}

export { getTime, responseParsing, getURLParams };

export type { NavigaeteCaseProps };
